@import "../../App.scss";
.wrapper {
    margin-top: 80px;
}
.wrapper .main_container {
    padding: 30px 30px 40px 30px;
}
.fleet_dashboard {
    .dashboard_heading {
        font-size: 20px;
        font-weight: 400;
        margin: 0px;
        line-height: 20px;
        margin-bottom: 10px;
        color: $black;
    }
    .fleet_card_list {
        max-height: 567px;
        overflow-x: auto;
    }
}
.MuiCircularProgress-colorPrimary {
    position: relative;
    left: 50%;
    margin-top: 15vh;
    width: 100px !important;
    height: 100px !important;
    color: $theme-secondary !important;
}
.inner_progress {
    margin-left: -150px;
    margin-top: 25vh;
}
.welcome-back {
    font-family: "Open Sans", sans-serif;
    .dashboard-text { min-height: 85px; padding: 0px 34px;
        margin: 0 auto;
    }
    .MuiPaper-rounded {
        box-shadow: none;
    }
    .user-name {
        padding: 29px 33px;
        margin-bottom: 17px;
        background: $theme;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        h1 {
            margin: 0;
            font-size: 24px;
            line-height: 28px;
            font-weight: 700;
            color: $white;
        }
    }
    .listing-item {
        text-align: center;
        .MuiPaper-rounded {
            position: relative;
            min-height: 315px;
        }
        img {
            margin-top: 20px;
        }
        h2 {
            margin: 15px 0 10px 0;
            padding: 0;
            font-size: 20px;
            line-height: 28px;
            color: $theme-secondary;
            font-weight: 600;
        }
        p {
            padding: 0 0 15px 0;
            margin: 0;
            font-size: 18px;
            line-height: 28px;
            color: $welcome-back-content;
            font-weight: 400;
        }
        .bottom-btn {
        margin-top: 5px;
            text-align: center;
            position: absolute;
            bottom: 20px;
            left: 0px;
            right: 0px;
            .create_fleet_btn {
                padding: 10px 20px;
                border-radius: 5px;
                border: 0;
                background: $theme;
                min-height: 30px;
                justify-content: center;
                font-size: 18px;
                font-weight: 400;
                color: $white;
                min-width: 170px;
                margin-bottom: 0px;
            &:hover{background: $theme-secondary;}
            }
        }
    }
    
    .MuiTypography-body1{font-family: "Open Sans", sans-serif;}
    .text_section{
        margin-top: 20px;
        .MuiCardContent-root{ padding: 20px;}
        h2{margin: 0 0 10px 0; padding: 0; text-align: left; font-size: 18px; line-height: 28px; color:$text-section-paragraph; font-weight: 600;}
        p{ font-size: 18px; font-weight:400; color: $text-section-paragraph;}
        p + p{ margin-top: 20px;}
    }
    .saved_section{
        margin-top: 20px; text-align: center;
        .MuiCardContent-root{ padding: 24px 18px;}
        .dashboard-data-number{ font-size: 24px; margin-bottom: 10px; font-weight: 600; color:$charge-btn; text-align: center;}
        h2{margin: 0 0 10px 0; padding: 0; text-align: left; font-size: 18px; line-height: 18px; color:$welcome-back-content; font-weight: 600;}
        p{ font-size: 12px; padding-bottom:0px; min-height: 80px; font-weight: 400; text-align: left; color: $welcome-back-content;}
        div + p, .download_action p{text-align: center; padding-bottom: 5px; min-height: auto; color: $black}
        img.cloud{ margin:5px 0px;}
        img.caret_green{ display: none;}
        .saved_section_inside{ cursor: pointer; position: relative; 
            .MuiCard-root{border: 1px solid $bg-overlay-border;}
            .download_action{ display: none; background:rgba(235, 235, 235, 0.8); position: absolute; left: 8px; top:0px;  width: calc(100% - 16px); height: calc(100% - 8px);
            img{ margin-top: 120px;}
            }
            &.active{
                img.caret{ display: none;}
                img.caret_green{ display: inline-block;}
            }
            &:hover{
                img.caret{ display: none;}
                img.caret_green{ display: inline-block;}
                .download_action{ display: block; transition: all ease-in-out; transition-duration: 0.5s;}
            }
        }
    }
    .bottom-section {
        text-align: center;
        margin-top: 20px;
        h2 {
            margin: 0 0 10px 0;
            padding: 0;
            text-align: left;
            font-size: 18px;
            line-height: 28px;
            color: $welcome-back-content;
            font-weight: 600;
        }
        .text {
            font-size: 18px;
            clear: both;
            max-width: 700px;
            line-height: 26px;
            font-weight: 400;
            color: $welcome-back-text;
            display: inline-block;
            border-bottom: 1px solid $dashboard-border-color;
            padding: 0 30px 12px;
        }
        .feedback {
            margin-top: 20px;
            a {
                text-decoration: none;
                font-size: 18px;
                line-height: 26px;
                color: $theme-secondary;
                font-weight: 600;
            &:hover{color: $theme}
            }
        }
        .support-text {
            margin-top: 20px;
            text-align: center;

            .icon-box {
                position: relative;
                vertical-align: top;
                display: inline-block;
                background: $theme-secondary;
                border-radius: 5px;
                padding: 10px 0;
                margin-top: 10px;
                &:before {
                    position: absolute;
                    left: 50%;
                    bottom: -20px;
                    margin: 0 0 0 -12px;
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 20px solid $theme-secondary;
                }
                img {
                    margin: 0 10px;
                    vertical-align: bottom;
                    display: inline-block;
                }
            }
            .support-list {
                display: inline-block;
                padding: 0 0 0 30px;
                .MuiListItem-gutters {
                    padding: 0 0 25px 0;
                    display: block;
                    text-align: center;
                    font-size: 14px;
                    line-height: 20px;
                    color: $theme-secondary;
                    font-weight: 600;
                }
                a {
                    text-decoration: none;
                    color: $theme-secondary;
                &:hover{color:$theme}
                }
            }
        }
    }
}

.wrapper .inside_page .text.feedback_text{ font-size: 18px; color: $text-section-paragraph;}

.terms_conditions_inside{margin: 10px 0px; padding: 0px;
p{ margin-bottom: 20px; font-size:16px; line-height: 22px; color: $black;}
iframe{width:100%; box-shadow: none; border:none; min-height: 70vh;
body{ background: #fff;}
}
}

/*---------Responsive CSS Start--------------*/
@media screen and (max-width:1920px) {
    .welcome-back .saved_section p{font-size: 13px; min-height: 70px;}
    .welcome-back .bottom-section .support-text{ min-height: 101px;}

}
@media screen and (max-width:1480px) {
    .welcome-back .saved_section p{min-height: 80px;}
}
@media screen and (max-width:1400px) {
   .welcome-back{
    .saved_section{
        p{font-size: 12px; min-height: 75px;}
        .dashboard-data-number{font-size: 19px;}
    }
}
}
@media screen and (max-width:1370px) {
    .welcome-back .saved_section p{min-height: 80px;}
    .welcome-back {
        .bottom-section {
            .text{font-size: 17px;}
            .support-text{ 
                min-height: 75px; 
                margin-top: 50px;
                .support-list {
                    float: right;
                     padding: 0 ;

                     .MuiListItem-gutters{font-size: 14px;}
                }
            }
        }
    }
}
@media screen and (max-width: 1290px) {
    .welcome-back .saved_section p{min-height: 90px;}
}
@media screen and (max-width: 1280px) {
    .welcome-back .saved_section p{min-height: 90px;}
    .welcome-back {
        .dashboard-text { padding: 0px;}
        .bottom-section {
            .text {
                font-size: 15px;
            }
            .support-text {
                margin-top: 30px; 
                min-height: 115px;
                .support-list {
                    .MuiListItem-gutters,
                    a {
                        font-size: 13px;
                    }
                }
                .feedback a{font-size: 17px;}
                .icon-box img{margin: 0 8px;}
            }
        }
    }
}
@media screen and (max-width: 1260px) {
    .welcome-back .saved_section p{min-height: 90px;}
}

@media screen and (max-width: 1210px) {
    .welcome-back {
        
        .bottom-section {
           
            .support-text {
             .support-list {
                .MuiListItem-gutters,
                a {
                    font-size: 11px; padding-bottom: 15px;
                }
            }
           
        }
        }
    }

}

@media screen and (max-width: 1160px) {
    .welcome-back .listing-item h2 {
        font-size: 16px;
        line-height: 28px;
    }
    .welcome-back .listing-item p {
        font-size: 16px;
        line-height: 25px;
    }
    .welcome-back 
    .bottom-section{
        .feedback{ margin-top: 14px;
            a{font-size: 16px;}
        }
     .support-text {
        margin-top: 0;
        .support-list {
            float: left;
            padding: 0px 0 88px 10px;
            width: calc(100% - 67px);
            text-align: left;
            li{ word-break: break-all;}
            .MuiListItem-gutters,
            a {
                text-align: left;
                padding: 0 0 14px 0;
            }
        }
        .icon-box{margin-top: 0; float: left;}
    }
} 
.welcome-back .saved_section {
    .saved_section_inside {
        .download_action img {margin-top: 130px;}
        .MuiCard-root{min-height:300px}
        .MuiCardContent-root{padding: 24px 10px;}
        .dashboard-data-number{font-size: 17px;}
    }
    }
}

@media screen and (max-width: 1144px) {
    .welcome-back .bottom-section .support-text .icon-box img{ margin: 0px 6px;}
    .welcome-back .bottom-section .support-text .support-list .MuiListItem-gutters, .welcome-back .bottom-section .support-text .support-list a{ padding: 0px 0px 4px 0px;}
}

@media screen and (max-width:1096px) {
    .welcome-back .bottom-section .support-text .support-list a{ font-size: 10px;}
    .welcome-back .bottom-section .support-text .support-list{ width:calc(100% - 58px)}
}

@media screen and (max-width: 1075px) {
    .welcome-back .saved_section{
         p {min-height: 90px;}
         .saved_section_inside {
         .MuiCard-root{min-height:324px}
         }
    }
    .welcome-back .bottom-section .support-text .icon-box img{ margin: 0px 6px;}
    .welcome-back .bottom-section .support-text .support-list a{ font-size: 8px;}
    .welcome-back .bottom-section .support-text .support-list{ padding-bottom: 105px;}
}
@media screen and (max-width: 960px) {
.welcome-back .bottom-section .support-text .support-list{
    padding: 0 0 0 30px;
    width: inherit;
}
}