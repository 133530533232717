@import "../../App.scss";

.pricing_day_label {
    color : $black;
    font-size: 12px;
    display: block;
    padding-top:5px;
}

.font_weight_header {font-weight: 600;}

.font_weight_data {font-weight: 400;}

.margin_top {margin-top: 5px;}

.pl_pr_10 { padding-left: 10px; padding-right: 10px}

.dialog_container.edit_detail_section .MuiDialog-container .MuiDialog-paperWidthSm{ min-width: 1000px; max-width:1000px; max-height: 98vh;}

.chargesession_detail .chargesession_deatiltab   {
    .list-detail {
        .gray_bg {
            background: $charge-tab_bg;
            overflow: hidden;
            margin: 0 20px 0 0;
            border-radius: 5px;
            padding: 30px 15px 10px;
            .MuiListItem-root {
                label {font-size: 14px;font-weight: 600;color: $black;}
                .sab_label{ font-size: 10px; font-weight: 400; display:block; color: $black;}
                span {font-size: 16px;font-weight: 700;display: block;margin-top: 16px;}
                .sab_margin_top { font-size: 16px;font-weight: 700;display: block;margin-top: 0px;}
            }
        }

    }
}