@import "../../App.scss";

.pagination-card {
    display: flex;
    border:none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 45%;
}

.paginationItem {
    background: $white;
    border: 2px solid $gray-text-label;
    padding: 10px 15px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
}

.paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prev,
.next {
    background: $white;
    border: none;
    padding: 5px;
    color: $theme-secondary;
    margin: 0 5px;
    cursor: pointer;
    &:focus{outline: none;}
}

.paginationItem.active {
    border: 1px solid $offline-border;
    color: $offline-border;
    pointer-events: none;
}

.prev.disabled,
.next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: $placholder-color;
}