@import "../../../../App.scss";

/*-------------Pricing Plan CSS Start-------------------*/
.pricing_list {
    ul {
        padding: 0px;
        li {
            padding: 0px;
            cursor: pointer;
        }
    }
}

.button_row_white {
    margin-top: 10px;
    .btn_white {
        border: none;
        width: 100%;
        cursor: pointer;
        margin-left: 0px;
    }
    .text_btn_theme {
        background: none;
        border: 1px solid $gray-c-color;
    }
}

.pricing_section {
    margin-top: 30px;
    .ml-2 { margin-left: 2px}
    .wrapper_main {
        display: flex;
        .pricing_heading {
            display: inline-block;
            width: 350px;
            font-size: 18px;
            color: $black;
            .label {
                font-size: 12px;
                font-weight: 400;
                line-height: 13px;
                color: $gray-text-label;
            }
            .result {
                font-size: 12px;
                font-weight: 400;
                color: $black;
                line-height: 17px;
            }
        }
        .access-heading {
            display: inline-block;
            width: 350px;
            font-size: 18px;
            font-weight: 600;
            color: $theme-secondary;
        }
        .pricing_second_heading {
            vertical-align: top;
            display: inline-block;
            width: 200px;
            .label {
                text-align: left;
                font-size: 10px;
                font-weight: 500;
                line-height: 13px;
                color: $gray-text-label;
                margin-top: 8px;
            }
            .result {
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: $black;
                line-height: 17px;
            }
        }
        .pricing_staion {
            text-align: left;
            font: normal 14px;
            letter-spacing: 0px;
            color: $charge-btn;
            opacity: 1;
            margin-top: 20px;
        }
        .pricing_edit {
            float: right;
            vertical-align: top;
            .action_icon {
                background: $light-grayo1;
                border-radius: 3px;
                height: 28px;
                width: 28px;
                float: right;
                vertical-align: top;
                &.no_bg {
                    background: none;
                }
                .top_action_btn {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
    .location_access {
        .label {
            vertical-align: top;
            font-weight: 600;
            width: 150px;
            line-height: 24px;
            font-size: 14px;
            display: inline-block;
            color: $black;
        }
        .result {
            vertical-align: top;
            font-weight: 400;
            line-height: 24px;
            font-size: 14px;
            color: $black;
            display: inline-block;
            word-wrap: break-word;
        }
    }
}
/*-------------Pricing Plan CSS  End-------------------*/

/*----------------------------------------------------------Pricing Plan -Add Edit Dialog Box----------------------------------------------------*/


.dialog_container_pricing {
    &.dialog_container_center_pricing {
        .MuiDialog-container {
            align-items: center;
            .MuiDialogContent-root {
                padding: 0;
                .border_bottom {
                    border-bottom: 1px solid $border-color;
                }
            }
        }
        .MuiPaper-rounded {
            border-radius: 5px;
        }
        .MuiDialog-paperWidthSm {
            min-width: 1115px;
            max-width: 1115px;
            max-height: 98vh;
        }
    }
    .MuiDialog-container {
        align-items: center;
    }
    .MuiDialog-paperWidthSm {
        min-width: 840px;
        max-width: 840px;
        max-height: 98vh;
    }
    .MuiDialog-paper {
        margin-bottom: 0px;
        .form_col .single_line .w_100per.full_w {
            width: 100%;
        }
    }
    .MuiPaper-rounded {
        border-radius: 5px 5px 0px 0px;
    }
    .dialog_title {
        background: $body-bg;
        padding: 20px 30px 14px 30px;
        h4 {
            margin: 0px;
            font-size: 20px;
            font-weight: 500;
            color: $black;
            display: inline-block;
        }
    }
    .button_row {
        margin-top: 0;
        .btn_white {
            background: $white ;
            margin-left: 10px;
            border-radius: $theme-radius;
            padding: 0px 14px;
            font-size: 14px;
            font-weight: 400;
            height: 40px;
            text-decoration: none;
            text-transform: capitalize;
            color: $theme-secondary;
            &.blue_bg {
                background: $theme-secondary ;
                color: $white;
                margin-left: 10px;
                &:hover {
                    background: $theme-secondary ;
                }
            }
            &.disable {
                cursor: none;
                background: $disable;
                color: rgba(0, 0, 0, 0.26);
            }
        }
        .text_btn_theme {
            background: none;
        }
    }
    .outline_btn {
        border: 1px solid $theme-secondary;
        color: $theme-secondary;
        text-transform: capitalize;
        background: none;
    }
    .scroll_content {
        max-height: 100vh;
        overflow-y: auto;
    }
        .defult_form {
            padding: 8px 50px;
            &.form_col .check_box_label label {
                font-size: 14px;
                font-weight: 500;
                color: black;
                display: inline-block;
                margin-top: 4px;
            }
            .form_col_check.checkbox .MuiCheckbox-root {
                padding: 0;
                color: $theme-secondary;
            }
            &.error_msg_cost {
                color: $alert-marker;
                font-size: 10px;
                left: 270px;
            }
            .border_bottom {
                border-bottom: 1px solid $border-color;
            }
        }    
        .MuiLinearProgress-root {
            &.MuiLinearProgress-colorPrimary {
                background-color: $linear-loader-bg;
            }    
            .MuiLinearProgress-barColorPrimary {
                background-color: $theme-secondary;
            }
        }

    .defult_form_configure {
        padding: 25px 50px;
        color: black;
        font-weight: 500;
        .form_col {
            &.single_line {
                margin-bottom: 10px;
            }
            .check_box_label {
                padding-top: 2px
            }
        }
        .form_col_radio {
            margin-bottom: 20px;
            position: relative;
            &.radio_button {
                label {
                    margin-top: -9px;
                }
                label:first-child {
                    margin-left: 0px;
                }
                .MuiRadio-colorPrimary.Mui-checked {
                    color: $theme-secondary;
                }
                .MuiSvgIcon-root {
                    color: $theme-secondary;
                }
                .MuiFormControlLabel-label {
                    font-size: 14px;
                    color: $black;
                    font-weight: 500;
                }
            }
        }
        .form_col_check.checkbox {
            margin-bottom: 20px;
            .MuiCheckbox-root {
                padding-right: 4px;
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
                color: $theme-secondary;
            }
        }
        .form_col_configure {
            margin-bottom: -25px;
            margin-top: 10px;
        }
        .form_col_button {
            margin-top: 20px;
            margin-bottom: 40px;
            .single_line .bottom-btn {
                .margin-left {
                    margin-left: 10px;
                }
                .create_pricing_btn_selected {
                    border-radius: 8px;
                    border: 3px solid $theme-secondary;
                    background: $pricing-energy-background;
                    width: 190px;
                    min-height: 50px;
                    margin-top: 15px;
                    justify-content: center;
                    font-size: 18px;
                    font-weight: 500;
                    color: $black;
                }
                .create_pricing_btn {
                    border-radius: 8px;
                    border: 3px solid $gray-c-color;
                    background: $white ;
                    width: 190px;
                    min-height: 50px;
                    margin-top: 15px;
                    justify-content: center;
                    font-size: 18px;
                    font-weight: 500;
                    color: $black;
                }
            }
            .border_bottom {
                border-bottom: 1px solid $border-color;
            }
            .form_col {
                label {
                    margin-top: -9px
                }
            }
        }
        .dialog_title {
            background: $body-bg;
            padding: 13px 30px;
            h4 {
                margin-top: 10px;
            }
        }
    }
}

/*----------------------------------------------------End of Pricing Plan - Add Edit Dialog Box-------------------------------------------------------*/



/*---------------------------------------pricing Plan Add Delete row CSS -------------------------------------------*/

.time_of_day {
    .pc_time_padding {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        .label {
            color: $black
        }
        .header_padding .label {
            color: $black;
            padding-left: 14px;
        }
    }
    .single_line_dropdown {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-width: 120px;
    }
    .defult_form {
        margin-bottom: -30px;
        padding : 0;
        .form_col {
            margin-bottom: 0;
            padding: 0 10px 10px;
        }
    }
    .defult_form .form_col .MuiSelect-select {
        width: 65px;
        font-size: 14px;
        padding: 0 9px;
    }
    .month_separator {
        border-bottom: 1px solid $border-color;
        padding-top: 25px;
        padding-bottom: 20px;
    }
    .month_separator:last-child {
        border-bottom: 0;
    }
    .custom_input {
        .pricing_rate .MuiInputBase-root {
            width: 90px;
            height: 40px;
            font-size: 14px;
            color: $black
        }
         .MuiIconButton-root {
            padding: 5px 5px 1px;
        }
    }
}
.error_msg_cost {
    color: $theme-secondary;
    font-size: 14px;
    left: 270px;
    padding-left: 13px;
    font-weight: 500;
}

.mt-25{ margin-top: 25px ;}
/*---------------------------------------pricing Plan Add Delete row CSS End-------------------------------------------*/

/*------------------------------------------Delete Pricing Plan -----------------------------------------------------*/



/*------------------------------------------Delete Pricing Plan End -----------------------------------------------------*/
/*-------------Apply Pricing Plan CSS  start-------------------*/

.description_add_usergroup {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: $charge-btn;
    line-height: 17px;
    margin-left: 5px;
    margin-top: 5px;
  }
  .description {
    font-size: 12px;
    font-weight: 400;
    color: $gray-text-label;
    line-height: 17px;
  }
 
  .srv-validation-message{
    color: $alert-marker; 
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.inner_progress_commands {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.plan_text {
    color: $charge-btn;
    font-weight: 300;
}
/*-------------Apply Pricing Plan CSS  End-------------------*/

/*---------Detailed Pricing Plan CSS Start ---------------------------------*/
.pricing-detailed {
    .detailed-splitup .common_form{
        overflow-x: auto;
    }    
    .common_form .result .form_control .input_text {width: 80px;}
    .bottom_border, .split_bottom_border {border-bottom: 1px solid $gray-text1; padding-bottom: 10px;}
    .split_bottom_border_days {border-bottom: 1px solid $gray-text1 ; margin-top:12px;}
    .bottom_border:last-child {border-bottom: 0;}
    .ml-15 {margin-left: 15px;}
    .ml-20 {margin-left: 20px;}
    .ml-30 {margin-left: 30px;}
    .ml-40 {margin-left: 40px;}
    .ml-60 {margin-left: 60px;}
    .ml-80 {margin-left: 80px;}
    .ml-100 {margin-left: 100px;}
    .ml-120 {margin-left: 120px;}
    .mt-20 {margin-top: 20px}  
    .mt-25 {margin-top: 25px}  
    .mt-15 {margin-top: 15px} 
    .mr_dayindex-one {margin-right: -667px}
    .mr_dayindex-two {margin-right: -563px}
    .mr_dayindex-three {margin-right: -460px}
    .mr_dayindex-four {margin-right: -357px}
    .mr_dayindex-five {margin-right: -253px}
    .mr_dayindex-six {margin-right: -150px}
    .mr_dayindex-seven {margin-right: -47px}
    .MuiGrid-root span {
        font-size: 14px;
    }
    .MuiGrid-root .child_split {
        font-size: 14px;
        margin-top: 32px; 
        margin-left: 40px;
        margin-bottom: 20px;
    }
}