@import "../../App.scss";

.breadCrumb-container {
    height: 40px;
    display: flex;
    margin-left: 15px;
    .breadCrumbs {
        display: flex;
        align-items: center;
        background-color: $white;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        .icons {
            display: flex;
            align-items: center;
            .homeIcon {
                color: $theme-secondary;
                font-size: 20px;
                margin-left: 10px;
                cursor: pointer;
            }
        }
        .navigateNextIcon {
            color: $text-color;
            font-size: 22px;
            cursor: pointer;
        }
        .moreHorizIcon {
            color: $theme-secondary;
            font-size: 22px;
            cursor: pointer;
        }
        .expandIcons {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .inActive-links {
            display: flex;
            align-items: center;
            .breadcrumb-typography {
                color: $gray-text-label;
                font-size: 14px;
            }
        }
        .show-active-links {
            display: flex;
            align-items: center;
            .breadcrumb-link {
                font-size: 14px;
                cursor: pointer;
                color: $theme-secondary;
            }
        }
        .hide {
            display: none;
        }
    }
    .triDesign {
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-left: 20px solid $white;
        border-bottom: 20px solid transparent;
    }
    .noBreadCrumbs {
        display: none;
    }
}