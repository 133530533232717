@import "../../App.scss";

.location_settings {
    .line-height {
        line-height: 18px;
    }
}

.download_link {
    padding-bottom: 10px;
    float:right;
    cursor: pointer;
}

.positive {
    color: $complete-text;
}

.nagative {
    color: $danger-red;
}

.zone_location-table.default_table .MuiDataGrid-root .MuiDataGrid-row.disable_row {
    cursor: default;
}

.wrapper .zone_location-table {
    .MuiDataGrid-viewport,
    .MuiDataGrid-row,
    .MuiDataGrid-renderingZone {
        max-height: none !important;
    }

    .MuiDataGrid-window {
        position: relative;
        padding-bottom: 25px;
    }

    .MuiDataGrid-cell {
        max-height: none !important;
        overflow: auto;
        white-space: initial;
        line-height: 16px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
    &.zone_location_overflow_visible {
        .MuiDataGrid-cell {
            overflow: visible;
        } 
    }
}