/*-------Base Theme Style Sheet---*/

/*-------Theme Color variable----*/
$theme: #65A844;
$theme-secondary: #25556E;
$text-color: #4D4D4F;
$placeholder-text-color: #343A40;
$placholder-color:#999999;
$white:#ffffff;
$body-bg:#F5F5F5;
$black:#000000;
$gray-text1:#cccccc;
$progress_bar_bg:#EEEEEE;
$gray-text-label:#666666;
$border-color:#E5E5E5;
$gray-btn:#DDE5E9;
$border-color-style: #707070;
$gray-c-color:#cccccc;
$del-group: #D32B2D;
$charge-tab_bg: #F6F6F6;
$charge-btn: #205171;
$welcome-back-content: #363636;
$welcome-back-text: #6C6C6C;
$dashboard-border-color:#E6E6E6;
$filter-text:#676668;
$text-section-paragraph:#363636;
$ter_status:#DC1400;

/*-------State Color variable----*/
$available-bg:#66A843;
$available-border:#ffffff;
$connected-bg:#FDBB1B;
$connected-text:#ffffff;
$connected-border:#ffffff;
$parked-bg:#E0EED9;
$parked-text:#3B771E;
$parked-border:#3A8814;
$transit-bg:#DDE5E9;
$transit-text:#074F74;
$transit-border:#25556E;
$reserved-bg:#DDE5E9;
$reserved-border:#25556E;
$charging-bg:#47A0D9;
$unavailable-bg:#898887;
$charging-text:#ffffff;
$charging-border:#ffffff;
$complete-bg:#50BCF4;
$complete-text:#ffffff;
$complete-border:#ffffff;
$error-bg:#FFA06F;
$error-text:#ffffff;
$error-border:#ffffff;
$fault-bg:#FFA06F;
$fault-text:#ffffff;
$fault-border:#ffffff;
$offline-bg:#999999;
$offline-text:#ffffff;
$offline-border:#ffffff;
$alert-marker:#FF0000;
$badge_color:#C90000;
$driver-available-bg:#DDE5E9;
$n-charging:#E0A006;

$suspended-bg:#E96A24;
$suspended-text:#ffffff;
$suspended-border:#ffffff;

$driver-onduty-bg:#66A843;
$driver-onduty-text:#ffffff;
$driver-not-available-bg:#888888;
$driver-not-available-text:#ffffff;
$driver-unavailable-bg:#888888;

$driver-suspended:#E96A24;
$battery-low:#E96A24;
$in-service:#E96A24;

$connected-bg-dark:#999999;
$charging-bg-dark:#FDBB1D;
$complete-bg-dark:#65A844;

$db-available:#e5e5e5;
$db-saprator-bg:#e5e5e5;
$light-grayo1:#eeeeee;
$low-placecolor:#999999;
$low-offlinecolor:#dddddd;
$danger-red:#A70404;
$off-peak: #00B9FF;
$peak:#FF489C;
$super-peak:#C365FD;
$bg-peak:#FFDAEB;
$off-peak-bg:#CCF1FF;
$super-peak-bg: #f3e0ff;
$sublabeltext:#888888;

$priority-2 :#00A8FF;
$priority-3: #C365FD;
$other-priority :#5DD622;
$low-green:#A6C38D;
$health-bar:#F2F2F2;
$menu-badge:#E0EED9;
$search-text:#91A9B6;
$dark-gray:#333333;


$low-e-color:#eeeeee;
$active-bg:#E0EED9;
$active-border:#65A844;
$progress-stop:F9A0A0;
$overlay-text-color :#FFFFCC;
$disable:#dddddd;
$training-detail-heading: #4D4C50;
$user_orange_bg:#D97139;
$user_yellow_bg:#F3BE48;
$userName-dark-blue:#2E4960;
$userName-sky-blue:#5992CA;
$userName-opaq-green:#49AA97;
$userName-dark-green:#689d48;
$userName-ligh-green:#ABCD82;
$userName-grey:#82878A;
$userName-dark-grey:#535354;
$userName-darkish-grey:#333435;
$user-name-bg:#ededed;
$tc-btn-bg:#CDCBCD;
$save-dropDown-bg:#00000029;

$pricing-energy-background: #EEF2F4;

$membership-rejected: #FBE1D3;
$lcd-display-text: #4E5DAC;
$linear-loader-bg: #B8C9D1;
$dropdown-bg:#21465C;

/*--------Font variable-----*/
$theme-font: 'Open Sans', sans-serif ;
$material-font: 'Material Icons Outlined';


/*--------Border variable-----*/
$divider: 1px solid #E5E5E5;
$theme-radius:5px;
$dark-divider: #bababa;
$bg-overlay:#EBEBEB;
$bg-overlay-border: #DEDDDF;


/*-----------Main Css Start---------*/
html, body {font-family: 'Open Sans', sans-serif !important; letter-spacing: inherit ; line-height: inherit; padding:0px ; font-size: 16px ; margin: 0px ; background: $body-bg ; 
  .MuiTypography-body1 {font-family: 'Open Sans', sans-serif !important}
  .MuiDataGrid-root {font-family: 'Open Sans', sans-serif !important;
    .MuiDataGrid-columnHeaderTitle{font-weight: 600;}
  }
}
body.base_theme{font-family: 'Open Sans', sans-serif; letter-spacing: inherit ; line-height: inherit; padding:0px ; font-size: 16px ; margin: 0px ; background: $body-bg ; }

.wrapper{background: $body-bg ; letter-spacing: 0.0em; line-height: 1.22; }

.top_header .back-arrow{ background-color: $white; width: 40px; height: 40px; border-radius:$theme-radius;
  .material-icons{ color: $theme-secondary; font-size: 16px;}
}

.disable{ background-color: $body-bg; cursor: none;}

.text-bold { font-weight: bold; color: $black;}

.error_msg3 {font-style: normal;position: relative;color: $alert-marker;font-size: 10px;display: inline-block;vertical-align: middle;left: 0px;top: 4px;}

/*-----------Button Css Start---------*/
.button_row{ margin-top: 20px;
  .btn_row_text{ font-size: 14px; font-weight: 400; color:$text-color;
    .blue_bg{margin-left: 10px;}
  }
  
}
.dialog_container .btn_white.increase_padding{padding: 0px 21px;}
.csv_download{text-decoration: none;}
.wrapper .text_link, .dialog_container .text_link{ background: none; padding: 0px; margin: 0px; font-size: 14px; color: $theme-secondary; text-transform: capitalize; font-weight: 400;
&:hover{ background: none;}

}

.wrapper .block_btn, .dialog_container .block_btn{ min-height: 50px; border-radius: $theme-radius; width: 100%; color: $white; font-weight: 400; background:$theme; font-size:16px; text-transform: inherit; margin:0px; 
  &:hover{ background: $theme;}
  &.disable{ cursor: none; background: $disable;}
  }

.wrapper .btn_white, .dialog_container .btn_white.increase_size{
  padding: 0px 23px;
}
.wrapper .btn_white, .dialog_container .MuiDialog-paper .btn_white{
  &.disable{ cursor: none; background: $disable;}
}

.wrapper .btn_white, .dialog_container .btn_white{ background: $white ; margin-left: 10px; border-radius : $theme-radius; padding: 0px 14px; font-size: 14px; font-weight: 400; height: 40px ; text-decoration: none; text-transform: capitalize ; color: $theme-secondary  ;
  &.border{border: 1px solid $gray-c-color}
  &:hover{background: $white ;}
    &.gry_bg{background: $gray-btn; color: $theme-secondary ; margin-left: 10px ;
    &:hover{background: $gray-btn; }
    }
  &.light_gry{background: $light-grayo1; color: $theme-secondary; margin-left: 10px ;
    &:hover{background: $light-grayo1; }
  }
  &.tc_btn{background: $tc-btn-bg; color: $white; 
    &:hover{background: $theme-secondary; }
  }
  &.delet_acct{background:$danger-red; color: $white ; margin-left: 10px ;
    &:hover{background:$danger-red;}
  }
  &.red_bg{background: $del-group; color: $white;
    &:hover{background: $del-group;}
  }
  &.small_btn{ font-size: 10px ; height: 30px; 
  .MuiButton-label{ height: 30px; line-height: 30px;}
  }
  &.blue_bg{background: $theme-secondary ; color: $white; margin-left: 10px ;
    &:hover{background: $theme-secondary ;}
  }
  &.btn_save_with_dropdown{margin-left: 0px;}
  &.download_report{border:none;width: 100%; height: 52px;cursor:pointer; background: $charge-btn;}
  &.cancel{background: $progress_bar_bg ; color: $theme-secondary;
  &:hover{background: $progress_bar_bg ;}
  }
  .MuiButton-label{ display: block; height: 40px; line-height: 43px;}
  }

  .dialog_container .save_dropdown_btn{background-color: $dropdown-bg; color:$white; min-width: 25px;padding: 0px; 
    &:hover{background: $dropdown-bg; }
  }
  
  .wrapper .outline_btn{ border: 1px solid $gray-c-color; color: $theme-secondary; text-transform: capitalize; background: none;}
  .dialog_container .outline_btn{ border: 1px solid $gray-c-color; color: $theme-secondary; text-transform: capitalize; background: none;}
  .wrapper .text_btn_theme, .dialog_container .text_btn_theme{ background: none;
  &:hover{background: none;}
  }
  .base_theme,  .dialog_container .text_btn{ font-size: 16px; font-weight:500; color: $black; text-transform: capitalize; padding: 0px; margin: 0px 15px;
  &.active{ color: $theme-secondary;}
  }
  
.error_text{color: $danger-red;font-size: 12px;}  
.error_prompt_msg{color: $danger-red;font-size: 14px;}  

.legend{ 
  .current{ margin-right: 5px; margin-top: -2px; border-radius: 50px; vertical-align: middle; background: $gray-text1; width: 10px; height: 10px; display: inline-block;}
  .average{ margin-right: 5px; margin-top: -2px; border-radius: 50px; vertical-align: middle; background: $gray-text1; width: 10px; height: 10px; display: inline-block;}
}
.global_cursor{.MuiDataGrid-cell{cursor: auto}}


/*-----------Status Css Start---------*/
.evse_list .stat_list { margin-top: 10px;}
.stat_list { margin-top: 0px;}
.status{ font-size: 12px; margin-right: 5px; text-align: left; color: $white;  border-radius:5px; min-width:80px; height:28px; display: inline-block;
 span.status_text{ line-height: 28px; padding: 0px 7px; font-weight: 500;}
 	span.status_number{ line-height: 28px; color: $white;display: inline-block; float: right; padding: 0px 7px; height: 28px; min-width:28px; text-align: center; border-left:1px solid $white;}	
  &.available{ background: $available-bg;}
  &.connected{ background: $connected-bg ; color: $connected-text;
    span.status_text{color: $connected-text;}
  }
  &.reserved{ background: $reserved-bg ; }
  &.transit{ background: $transit-bg; color: $transit-text;
    span.status_text{color: $transit-text;}
  }
  &.parked{ background: $parked-bg; color: $parked-text; 
    span.status_text{ color: $parked-text; }
  }
  &.charging{ background: $charging-bg; color: $charging-text ;
    span.status_text{color: $charging-text ;}
  }
  &.unavailable{ background: $unavailable-bg; color: $charging-text ;	
    span.status_text{color: $charging-text ;}	
  }
  &.complete{ background: $complete-bg ; color: $complete-text ;
    span.status_text{color: $complete-text;}
  }
  &.error{ background: $error-bg; color: $error-text;
    span.status_text{color: $error-text;}
  }
  &.fault{ background: $fault-bg; color: $fault-text;
    span.status_text{color: $fault-text;}
  }
  &.in-service{ background: $error-bg; color: $error-text;
    span.status_number{ color: $text-color;}
    span.status_text{color: $text-color ;}
  }
  &.offline{ background: $offline-bg; }
  &.driver_available{ background: $driver-available-bg; }
  &.driver_onduty{ background: $driver-onduty-bg ; color: $driver-onduty-text;
    span.status_text{color: $driver-onduty-text ;}
  }
  &.driver_suspended{ background: $suspended-bg ; color: $error-text;
    span.status_text{color: $error-text ;}
  }

  &.driver_not_available{ background: $driver-not-available-bg; color: $driver-not-available-text ;
    span.status_text{color: $driver-not-available-text ;}
  }
  &.active{ background: $driver-onduty-bg ; color: $driver-onduty-text;
        span.status_text{color: $driver-onduty-text ;}
     }
     &.suspended{ background: $error-bg ; color: $error-text;
      span.status_text{color: $error-text ;}
     }
    &.pending{ background: $driver-not-available-bg; color: $driver-not-available-text ;
      span.status_text{color: $driver-not-available-text ;}
     }
}
/*-----------Status Css End---------*/

.wrapper main{padding:10px 30px 50px 30px; width: calc(100% - 200px);}
.wrapper .drawer_close + main{width: calc(100% - 73px);}
.section_title{ padding: 0px; margin: 0px 0px 10px 0px;
h2{ font-size: 20px; font-weight: 600; color: $black; margin: 0px; line-height: 20px;}
h3{ font-size:18px; font-weight: 600; color: $black; margin: 0px;}
.title_right h2{ font-size: 14px; text-align: right; color: $black; line-height: 20px;}
}

/*------Cards Css Start----*/
.wrapper .map_card{ border: none; background: none; border-radius: $theme-radius;}
.wrapper .small_card{border: none ; background: $white; border-radius: $theme-radius ; margin-bottom: 10px;
.card_content{ padding: 20px;}
}

.wrapper .map_card_evse{border: none ; background: $white ; border-radius: $theme-radius ; margin-bottom: 10px;
  .card_content{ padding: 20px ;}
}

.wrapper .list_card{border: none  ; width: 100%; background: $white ; border-radius: $theme-radius ; margin-bottom: 10px;
  .card_content{ padding: 20px 20px 17px 20px ;}
}

.wrapper .basic_card{border: none  ; background: $white ; border-radius: $theme-radius ; margin-bottom: 10px;
  .card_content{ padding: 20px ;}
  .MuiCardContent-root{ padding: 20px ;}
}
/*------Cards Css End----*/

.mid_section_right{
  .analytics{ margin-top: 10px;}
  .energy_legend{ text-align: right;
    .legend_list{ font-size: 10px; color: $black; display: inline-block; margin-left: 15px;
      span{ width: 12px; height: 12px; display: inline-block; border-radius: 50px; background:$gray-c-color; margin-right: 7px;}
      span.average{ background: $light-grayo1;}
    }
  }
}

.depot_bar{
  span{ display: inline-block; height: 20px; 
  &.charging{ background: $light-grayo1;}  
  &.available{background: $db-available;}
  &.offline{background: $gray-c-color;}
  &:first-child{ border-radius: 5px 0px 0px 5px;}
  &:last-child{ border-radius: 0px 5px 5px 0px;}
  }
}

.depot_state{
  
  .state{ text-align: center; font-weight: 400; margin: 14px 15px 0px 15px; line-height:12px}
  span{ width: 60px; height: 5px; border-radius: $theme-radius; display: inline-block;
    &.charging{ background: $light-grayo1;}  
    &.available{background: $db-available;}
    &.offline{background: $gray-c-color;}
  }
  .state_text{ font-size: 10px; font-weight: 500; text-transform: uppercase; color: $black;}
  .state_number{ font-size: 26px; font-weight: 400; color: $black; line-height: 32px;}
}

.depot_list{
  ul{ padding: 0px;
    li{ padding: 0px; cursor: pointer;
    .depot_graph{ height: 70px;}
    }
  }
}

.depot_details{
  .depot_information{ display: inline-block; vertical-align: top;
    .depot_location_map{ overflow: hidden; border: 1px solid $gray-text1; margin-right:10px; border-radius: $theme-radius; width:70px; height: 70px; display: inline-block; vertical-align: top;}
    .depot_info{ display: inline-block; vertical-align: top;
      .depot_name{ font-size: 18px; font-weight:600; color: $black; line-height: 22px; margin-bottom: 3px;
      span{ color: $black;}
      }
      .depot_addr{font-size:14px; font-weight:400; color: $text-color; line-height: 17px; margin-bottom: 10px; }
      .port_details{font-size:12px; font-weight:400; color: $text-color; line-height: 14px;
      span{ margin-right: 20px;
        img{ margin-right: 5px; display: inline-block; vertical-align: middle; margin-top: -2px;}
      }
      }
      .info_text{position: absolute; font-size:12px; font-weight:400; color: $text-color; line-height: 14px; margin-top: 6px; }
    } 
  }    
}


.depot_detail_head{
  .stat_list{ float: right; text-align: right; background: $body-bg; font-weight: 600;}
  .status{margin-right: 0px; margin-left:5px;}
}


.detail_page_tab{ margin-top: 10px;
  .defult_tab{ box-shadow: none;
  .tab_nav{ border-bottom: 1px solid $gray-c-color; padding: 0px 10px;
    .tab_nav_item{ font-size: 14px; opacity: 1; margin-right: 30px; font-weight: 400; text-transform: capitalize; min-width: inherit; padding: 13px 10px; color: $text-color;
    &.Mui-selected{ font-weight: 500; color: $theme-secondary;}
    .alert_badge .MuiBadge-badge{ background: $badge_color; right: 9px; top:12px; border-radius: 3px; height: 18px; min-width: 18px;}
    }
    .MuiTabs-indicator{ background-color: $theme-secondary; height: 5px;}
  }
  
  }
  .tab_detail{ padding-top:23px;}
}

.soc_level{ width: 100%;
.text_class{ display: inline-block; vertical-align: middle; margin-right: 12px; min-width: 29px;}
}
.stat_progress{ width:120px; display: inline-block; background: $progress_bar_bg; height: 10px;
.bar{ display: block; height: 10px; line-height: 10px; position: relative;
  // &:after{ content: ""; width: 8px; height: 4px; top:-4px; left: 100px; position: absolute; background: url(../src/assets/images/icons/ic_arrow_drop_down_24px.svg) no-repeat;}
  &.connected{ background: $connected-bg-dark;}
  &.charging{ background: $charging-bg-dark}
  &.complete{ background: $complete-bg-dark;}
  &.est{background: $charging-bg-dark;}
  &.battery_full{ background: $complete-bg-dark}
  &.battery_avg{ background: $charging-bg-dark}
  &.battery_low{ background: $battery-low}
 }
}

.wrapper .position_fixed_head{ background:$body-bg; position: sticky; position: -webkit-sticky; top: 80px; z-index: 999;}
.wrapper .position_fixed_nav{ background:$body-bg ; position: sticky ; position: -webkit-sticky; top:163px; z-index: 999;}

.wrapper .scroll_effect .position_fixed_nav{ top:108px; transition: all 0.3s ease-in-out;}
.wrapper .scroll_effect .depot_information{ height: 80px; transition: all 0.8s ease-in-out;}
.wrapper .scroll_effect .depot_location_map { opacity: 0; height: 0px ; width:0px ; transition: all 0.8s ease-in-out;}
.wrapper .scroll_effect .depot_addr{ opacity: 0; height: 0px ; transition: all 0.8s ease-in-out;}

.alert_unread_mark span{ margin-right: 14px; width: 10px; height: 10px; display: inline-block;  border-radius: 50px;
&.unread{background: $alert-marker;}
}

/*-------------Information Tab-------------------*/
.information_section{ margin-top: 30px;
  .label{font-size: 14px; font-weight: 500;}
  .result{font-size: 14px; font-weight: 400;}
  .row {
    margin-bottom: 10px;
  }
  .custom_rows_margin{
    .row {
      margin-bottom: unset;
    }
  }
  .title_row{
    h2{ font-size: 16px; float: left; font-weight: 600; color:$theme-secondary; text-transform: uppercase; margin: 0px;}
    .action_icon{ background:$light-grayo1; border-radius: 3px; height: 28px; width: 28px; float: right; text-align: center;
      &.no_bg{ background: none;}
      .top_action_btn{ width: 28px; height: 28px;}
    }
  }
  .info_detail{
    .tb_info_section{ margin-top: 20px;
      .label{ font-size: 10px; font-weight:400; line-height: 13px; color: $gray-text-label;}
      .result{ font-size: 14px; font-weight: 400; color: $black; line-height: 17px;
      .label{ vertical-align: top; line-height: 24px;   font-weight: 600; color: $black; font-size: 14px; width:95px; padding-right: 10px; display: inline-block;}
      .result{ display: inline-block; vertical-align: top; width:calc(100% - 140px); line-height: 24px; }
     
    }
    &.delegate_contact .result .result {width: inherit;}
    }
    
  }
  .zone_section{margin-top: 20px; border-bottom: 1px solid $border-color-style; padding-bottom: 15px; margin-bottom: 10px;
    &:last-child{ border:none;}
    .zone_detail{
      .zone_map{ border: 1px solid $low-placecolor; border-radius: $theme-radius; margin-right: 20px;
      img{ display: block;}
      }
      .tb_zone_section{ margin-top: 20px;
        .label{ margin: 0px;}
      }

    }
    .zone_floor_info{
      .z_floor_list{ padding: 0px;
      .MuiListSubheader-root{ padding: 0px; line-height: inherit;}
      .list_head{ font-size: 14px; font-weight:500; color:$black; line-height: 19px; margin-bottom: 7px;
        .list_sub_head{ font-size: 12px; font-weight: 400; color: $text-color;}
      }
      li{ padding: 0px; font-size: 14px; font-weight: 400; color: $theme-secondary; line-height: 17px; margin-bottom: 7px;
        .zone_icon{ min-width: 30px; margin-right:5px; text-align: right; display: block;}
        img{ display: inline-block; vertical-align: middle; text-align: right; }
        span{ font-size: 11.5px; font-weight: 400; color: $theme-secondary; line-height: 17px; white-space: nowrap; overflow: hidden;
          text-overflow: ellipsis;}
      .MuiListItemText-root{ margin: 0px;}
      }
      }
      
    }
    .tb_zone_section{
      .label{ font-size: 10px; font-weight:400; line-height: 13px; color: $gray-text-label; margin-bottom: 10px;}
      .result{ font-size: 14px; font-weight: 400; color: $black; line-height: 17px; margin-bottom: 10px;
      .img_div{ display: inline-block; text-align: center; min-width: 20px; margin-right: 10px; vertical-align: middle;}
      }
      &.amenities{
        .result{ color: $black;}
      }
    }
  }
  &.location-details {
    .label{font-weight: 600;  color: $black;}
    .title_row {
      h2 {
        font-size: 20px;
        color: black;
        margin: 0px 0px 19px 0px;
      }
    }
  }
}
.clearfix{ clear: both;}

.dialog_container{ letter-spacing: 0.0em; line-height: 1.2;
  &.dialog_container_center{
    .MuiDialog-container{ align-items:center;}
    .MuiPaper-rounded{ border-radius: 5px;}
    .MuiDialog-paperWidthSm{ min-width: 1115px; max-width:1115px; max-height: 98vh;}
  }
  &.set_fee_dialog{
    .MuiDialog-paperWidthSm{ min-width: 725px; max-width:725px; max-height: 98vh;}
  }
  .MuiDialog-container{ align-items:center;}
  .MuiDialog-paperWidthSm{ min-width: 840px; max-width: 840px; max-height: 98vh;}
  .MuiDialog-paper{ margin-bottom: 0px;}
  .MuiPaper-rounded{ border-radius: 5px 5px 0px 0px;}
  .dialog_title{ padding: 20px 30px 14px 30px; 
    
  h4{ margin: 0px; font-size: 20px; font-weight: 600; color: $black; display: inline-block;}
    .closed_btn{ padding: 0px; width: 20px; height: 20px;
      .material-icons{ color:rgba(0, 0, 0, 0.5)}
    }
  }
  .history_charging_list{ padding: 30px; border-top: 1px solid $low-offlinecolor;
    .h_charge_list{ font-size: 14px;
      .label{ display: inline-block; vertical-align: top; font-weight: 500; line-height: 24px; color: $black; width: 38%;}
      .result{display: inline-block; vertical-align: top; font-weight: 400; width: 58%; line-height: 24px; 
        a{ color: $theme-secondary; font-weight: 500;}
      }
    }
  }
  .scroll_content{ max-height: 100vh; overflow-y: auto;}

  .charge_rate_set{
    .label{ width:165px; font-size: 14px; font-weight: 500;}
    .result{ width: 220px ; text-align: left ;
      
      .form_control .MuiInputBase-root .MuiSelect-select{ width: 200px; font-size: 12px; background-position: 195px center;}
    }
  }
  .charge_set_tab.list_form{
    .label{ width:165px; font-size: 18px ; font-weight: 500 ;}
    .list_row .result{ width: 440px ; text-align: left;
      
      .MuiAppBar-positionStatic{ box-shadow: none; height: 42px; border: 1px solid $gray-c-color; border-radius: $theme-radius; background: $white;}
      .MuiTab-textColorInherit.Mui-selected{ background: $body-bg}
      .MuiTabs-indicator{ display:none;}
      .MuiTab-root{ max-width: 50%; min-height: 40px; min-width: 50%; font-size: 14px; font-weight: 400; opacity: 1; color:$theme-secondary; text-transform: capitalize;}
      .MuiTab-root:first-child{ border-right: 1px solid $gray-c-color; border-radius: 5px 0px 0px 5px;}
      .MuiTab-root:last-child{border-radius: 0px 5px 5px 0px;}
    }
  }

  h5{ font-size:16px; font-weight: 500; color:$black; text-align: center; margin:0px 0px 15px 0px;}
  
  .default_table{  width:100%;
    .MuiDataGrid-row{background: $progress_bar_bg ;}
    .MuiDataGrid-root .MuiDataGrid-cell{ border-bottom: 1px solid $white;}
    .MuiDataGrid-root .MuiDataGrid-cell:first-child{ border-bottom:none;}
    .MuiDataGrid-root .MuiDataGrid-cell:last-child{ border-bottom:none;}

    .table .MuiDataGrid-columnHeaderWrapper{ color: $text-color;}
    .table .MuiDataGrid-window{ color: $text-color;}

    .MuiDataGrid-row:hover{background: $white;}
    .MuiDataGrid-row:hover .MuiDataGrid-cell{ color:$theme-secondary ;}
  }
 
  .charging_fee{
    h5{ text-align: left; margin-bottom: 0px;}
    .member_fee{ margin-top: 30px;
      .label{ width: 100%;}
      .result{width: 340px; text-align: left;}
    }
    .push_inside{ padding:5px 8px;}
  }

  .list_form{ margin-top:16px;

    .billing_detail_table {
      .label {width:calc(100% - 245px); margin-bottom: 0px; font-size: 12px;}
      .result{width:245px; margin-bottom: 0px;
        .table_title{ font-size: 11px; width: 60px; color: $black; text-align: center;}
        fieldset{ border-bottom:none; border-left: none;}
      .MuiGrid-item { text-align: left;}
      .form_control .MuiOutlinedInput-root{ border-radius: 0px;}
      .form_control .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{  border-bottom:none; border-left: none;}
      .form_control .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{  border-bottom:none; border-left: none;}
      }
      .result .MuiGrid-container .MuiGrid-root:first-child .form_control .MuiOutlinedInput-root fieldset{ border-left: 1px solid $gray-text1 ;}
      .list_row:last-child fieldset{ border-bottom: 1px solid $gray-text1 ;}
      .list_row:nth-child(2)  .result .MuiGrid-container .MuiGrid-root:first-child fieldset{ border-radius: 5px 0px 0px 0px;}
      .list_row:nth-child(2) .result .MuiGrid-container .MuiGrid-root:last-child fieldset{ border-radius: 0px 5px 0px 0px;}
      .list_row:last-child  .result .MuiGrid-container .MuiGrid-root:first-child fieldset{ border-radius: 0px 0px 0px 5px;}
      .list_row:last-child  .result .MuiGrid-container .MuiGrid-root:last-child fieldset{ border-radius: 0px 0px 5px 0px;  border-bottom: 1px solid $gray-text1;}
      .list_row:last-child  .result .MuiGrid-container .MuiGrid-root fieldset{  border-bottom: 1px solid $gray-text1;}
      .result .form_control .input_text{  text-align: right; font-size: 12px; font-weight: 400; color: $low-offlinecolor; width:60px ; height: 40px; padding: 10px 10px 10px 10px;}
    }

    .label{ margin-bottom: 10px; font-size: 14px; color: $black; line-height:16px; display: inline-block; vertical-align: middle; width:165px; text-align: left;
      span{ font-size: 10px; color:$offline-border; display: block;}
      span.top_text{ font-size: 12px; color:$black; display: block;}
    }

    .result{ margin-bottom: 10px; display: inline-block; vertical-align: middle; text-align: right; width:77px ;
      &.text_label{ font-size: 12px; font-weight: 400; color: $text-color; height: 40px; line-height: 40px; text-align: center;
        span{ font-weight: 500; margin-right: 13px;}
      } 
      .form_control{
        &.gra_period .input_text{ text-align: left; padding: 10px 10px 10px 10px;
          .MuiInputBase-input{ text-align:left;}
        }
        .MuiOutlinedInput-root{ border-radius: $theme-radius; background: $white;}
        .MuiOutlinedInput-root.disable{ background: $body-bg;}
        .MuiSelect-selectMenu{  text-align: left; font-size: 12px; font-weight: 400; color: $text-color;}
        .MuiSelect-select{ background: url(./assets/images/icons/ic_expand_more.svg) no-repeat 60px center; border: none; width:60px; height: 20px; padding: 10px; border-radius: $theme-radius; font-size: 12px; font-weight: 400; color: $text-color;}
        .MuiSelect-icon{ display: none;}
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{  border: 1px solid $gray-text1;}
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{ border: 1px solid $gray-text1;}
        
        .input_text{  text-align: right; font-size: 12px; font-weight: 400; color: $low-offlinecolor; width:80px; height: 40px; padding: 10px 20px 10px 10px;
        .MuiInputBase-input{ text-align: right; padding: 0px;}
        .MuiTypography-root{font-size: 12px; color:$low-placecolor}
        }
      
      }
    
    }
  }
  .table_footer .btn_white.blue_bg{ margin-left: 0px;}

  .MuiLinearProgress-root {
    &.MuiLinearProgress-colorPrimary {background-color: $linear-loader-bg;}
    .MuiLinearProgress-barColorPrimary {background-color: $theme-secondary;}
  }
}

.mb-20{ margin-bottom: 20px;}
.user-logo {width: 50px; height: 50px}
.depot_location_map{ .user-logo{width: 100%; height: 100%}}
.inner_progress_userGroup {position: fixed;z-index: 9;left: 0;right: 0;bottom: 0;top:0;margin: auto;}
.inner_progress {position: relative; z-index: 9; right: 0; margin: 0 auto;}
.inner_progress_marginset{margin-left: -40px; margin-top: 5px; margin-bottom: 5px;}
/*--------zone detail pop up--------*/
.station_zone{
  .zone_map{ border: 1px solid $border-color; border-radius: $theme-radius;}
  .zone_floor_info{
    .z_floor_list{ padding: 0px;
    .MuiListSubheader-root{ padding: 0px; line-height: inherit;}
    .list_head{ font-size:14px; position: inherit; font-weight:500; color:$text-color; line-height: 17px; margin-top: 20px; margin-bottom:10px;
      .circle{ width: 16px; height: 16px; border: 1px solid $border-color-style; border-radius: 50px; line-height: 15px; margin-right: 5px; font-size: 10px; text-align: center; color: $text-color; display: inline-block;}
    }
    li.MuiListItem-root{ padding: 0px 0px 0px 26px;  margin-bottom: 10px;
      .icon{ min-width: inherit; margin-right: 10px;}
      span{ font-size: 14px; font-weight: 400; color: $theme-secondary; line-height: 17px;}
    .MuiListItemText-root{ margin: 0px;}
    }
    }
    
  }
}

.analytics{
  .info_detail{
    p{ font-size: 14px; margin: 0px; line-height: 17px; margin-top: 10px; color: $text-color;}
  }
  .button_row{
    .btn_white.blue_bg{ margin-left: 0px;}
  }
  .analytics_graph{ margin: 20px 0px;}
  .legend{ font-size: 10px; font-weight: 400; color: $black; display: inline-block; margin-right: 15px;
    .marker{ width: 10px; height: 10px; display: inline-block; margin-right: 5px; margin-top: -2px; border-radius: 50px; vertical-align: middle; border: 1px solid $theme; background: rgba(101, 168, 68, 0.2);}
    .peak{ color: $theme-secondary; font-weight: 500; margin-left: 20px; margin-right: 5px; display: inline-block;}
  }
}

.station_stats{
  .stn_list{ padding-right:0px; width: 16%;
    .stats_title{ font-size: 12px; line-height: 14px; text-transform: uppercase;  font-weight: 500; color: $text-color; margin-bottom: 10px;}
    .stats_port{ margin-bottom: 5px;
      .stats_port_name{ font-size: 8px; line-height: 14px; font-weight: 400; color: $text-color;}
      .stats_port_bar{ width:90%; height: 10px; background: $progress_bar_bg; border-radius: $theme-radius;
      span{height: 10px;  border-radius: $theme-radius; background: $gray-text1; display: inline-block;}
      }
    }
  }
}

.cal_btn{ float: right;
  .select_days{ display: inline-block; vertical-align: middle;  border-radius:$theme-radius; background: $progress_bar_bg; border: none;
  .day_select_box{ min-width: 100px;
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{ border: none;}
    .MuiOutlinedInput-notchedOutline{ border: none;}
    .MuiSelect-icon{ color: $theme-secondary;}
    .MuiSelect-select:focus{background: $progress_bar_bg; border-radius:$theme-radius;}
  .MuiSelect-select{ min-height: 32px; padding-top: 0px; line-height: 34px; padding-bottom: 0px; font-size: 12px; color: $theme-secondary;}
  }
  }
  .choose_date{ display: inline-block; vertical-align: middle; margin-left: 10px; font-size: 12px; color: $theme-secondary;
    .MuiButtonGroup-contained{ box-shadow: none; border-radius: 5px;}
    .MuiButton-contained:hover{ box-shadow: none; }
    .MuiButton-contained{background: $progress_bar_bg;}
    .MuiButtonGroup-groupedContainedHorizontal:not(:last-child){ border-right: 1px solid $white;}
    .MuiSvgIcon-root{ color: $theme-secondary; }
    .MuiButton-label{font-size: 12px; font-weight: 400; color: $theme-secondary; line-height: 12px;} 
  }
}
 
.default_table.location-overview {
.table{
.port_icon_img img {
  display: block;
  margin: 20px auto;
  margin-right: 0px;
}
.port_icon_img {
  float: left;
  width: 27px;
  margin: 0 20px 0 0px;
}
}
}



.daily_select_option{ font-size: 12px; padding: 5px 10px; cursor: pointer;}


.station_details{
  .detail_page_tab .defult_tab .tab_nav{ padding: 0px 0px 0px 10px;}
  .information_section .info_detail .tb_info_section .row{  font-weight: 400; }
  .information_section .info_detail .tb_info_section .row .label{width:190px; vertical-align: top; font-weight: 600; line-height: 24px; font-size: 14px; color: $black; display: inline-block;}
  .information_section .info_detail .tb_info_section .row .result{ width:calc(100% - 190px); vertical-align: top; font-weight: 400; line-height: 24px; font-size: 14px; color: $black; display: inline-block; word-wrap: break-word;}
  .map_section{width:100%; margin-top: 20px; border-radius: $theme-radius;}
  .depot_information .depot_location_map{ background: $white;}
  .port_card{
    .MuiCardContent-root{ padding: 0px ;}
    
    .title_row{ background: $db-available; padding: 16px 20px;
      h2{ margin-top: 3px;
      .unitno{
        display:inline-block; margin-right: 10px;
      }}
      .status{ text-align: center; margin-right: 0px;}
    }
    .info_detail .port_details{ padding: 16px 20px;
      &.tb_info_section{margin-top: 0px;}
      .row{
        .label{ width: 150px;}
        .result{ width:calc(100% - 150px) }
      }  
      .blue_bg{ margin-left: 0px;}
     
    }
   
    
  }
}

.information_section .info_detail .tb_info_section .basic_card .info_detail .zone_card{
  .row{
    .label{ width: 150px ;}
    .result{ width:calc(100% - 150px) ;}
  } 
} 

.wrapper .information_section .info_detail .tb_info_section.zone_card{
  .row{
    .label{ width: 150px ;}
    .result{ width:calc(100% - 150px) ;}
  } 
} 

.station_details .port_card .vehicle_info_card{
  .row{
    .label{ width:180px ;}
    .result{ width:calc(100% - 180px) ;
    .select_control{ color: $theme-secondary ; font-weight: 500; width: 180px ; max-width: 100%; min-width: 100px ;
    .MuiSelect-select{color: $theme-secondary ; font-weight: 500;}
    }
    }
  } 
} 

.wrapper .tab_menu_select{ position: absolute; right: 0px; z-index: 999;
  .tab_menu_select_control{ margin-right: 0px;
    .MuiInputBase-root{ width: 180px;}
    .MuiSelect-select{ background:$white url(./assets/images/icons/ic_select_caret.svg) no-repeat 155px center; border:1px solid $db-saprator-bg; width: 180px; height:28px; line-height: 30px; border-radius: $theme-radius; font-size: 12px; font-weight: 500; color: $theme-secondary; padding: 6px 24px 6px 12px;}
    .MuiInput-underline:before{ border-bottom: none ;}
    .Mui-focused.MuiInput-underline:after{ border-bottom: none ;}
    .MuiSelect-icon{ display: none;}
  }
}


.vehicle_detail{
  .depot_information .depot_location_map{ background-color: $white;}
  
}

.vehicle_list{
  .depot_details .depot_information .depot_info .port_details span{ margin-right: 0px; display: block; margin-top: 8px;
  span{ min-width: 80px; display: inline-block; margin: 0px; }
  }
  .depot_list ul li .depot_graph{ height: 70px;}
  .depot_details .stat_list{ margin-top: 10px;}
}

.image_gallery{ margin-top: 13px;
.image-gallery-slide-wrapper{ background-color: $black; padding: 30px 0px;}
.image-gallery-left-nav{ background: url(../src/assets/images/icons/icon-prev.svg) no-repeat; filter:none; width: 50px; height: 50px; padding: 26px; left: 20px;}
.image-gallery-right-nav{ background: url(../src/assets/images/icons/icon-next.svg) no-repeat; filter:none; width: 50px; height: 50px; padding: 26px; right: 20px;}
.image-gallery-left-nav svg{ display: none;}
.image-gallery-right-nav svg{ display: none;}
.image-gallery-bullets{ bottom: 10px; }
.image-gallery-bullets .image-gallery-bullet{padding: 3px;}
.image-gallery-slide .image-gallery-description{ font-size: 16px; font-weight: 400; bottom: 10px;}
.image-gallery-fullscreen-button{ bottom:25px}
.image-gallery-thumbnail{ width: 100px; height: 100px; border-radius: 5px; border: 3px solid $white; }
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{ border: 3px solid $complete-bg-dark; }
.image-gallery-thumbnail.active:hover{ border: 3px solid $complete-bg-dark; }
.image-gallery-thumbnail.active .image-gallery-thumbnail-image, .image-gallery-thumbnail.focus .image-gallery-thumbnail-image{ border-radius:3px; width:calc(100% - 2px); height: 92px;  }
.image-gallery-thumbnail .image-gallery-thumbnail-image{ height: 92px; width:calc(100% - 2px); border-radius: 3px;}
.image-gallery-content .image-gallery-slide .image-gallery-image{ width: 100%; min-height: 305px; max-height: calc(100vh - 170px);}
.image-gallery-thumbnail:hover{ border: 3px solid $white;}
}

.depot_details .depot_information .depot_info .depot_name .status { margin-left: 20px; margin-top: 0px; vertical-align: middle;}

.soc_value{ margin-top: 18px; font-size: 14px;
  .soc_col{ display: inline-block; color: $gray-text-label; margin-right: 10px; min-width: 80px;
  .label{ font-weight: 500; color: $black; margin-right: 10px;}
  }
}
.charge_fee_cal{ padding: 17px 20px; text-align: right; position: relative;
  .disableEvents {
    pointer-events: none;
  }
  .cal_legend{position: absolute; top: -50px; text-align: right; right: 20px;  display: inline-block;
      .legend{ min-width: 80px; height: 28px; text-align: center; margin: 0px 0px 0px 10px; padding:5px 10px; border-radius:5px; background:$light-grayo1; border: 1px solid $light-grayo1; display: inline-block; vertical-align: top; font-size: 12px; font-weight: 400; color: $text-color;
      &.off_peak{ background: rgba(0, 185, 255, 0.2); border: 1px solid $off-peak;}
      &.peak{ background: rgba(255, 72, 156, 0.2); border: 1px solid $peak;}
      &.super_peak{ background: rgba(195, 101, 253, 0.2); border: 1px solid $super-peak;}
      }
  }

  .rbc-time-view{ border:none;}
  .rbc-time-header.rbc-overflowing{ border-right: none;}
  .rbc-timeslot-group{ border-bottom:1px solid $white;}
  .rbc-time-content{ border:none;}
  .rbc-day-bg + .rbc-day-bg{ border:none;}
  .rbc-time-content > * + * > *{ border-right: none; border-left: none;}
  .rbc-time-header-content{border-left: none; margin-bottom: 10px; margin-top: 10px;}
  .rbc-header{ border:none; font-size: 14px;  color: $text-color; font-weight: 500; text-transform: uppercase; margin-right: 10px;}
  .rbc-time-slot{ font-size:12px; color: $text-color; font-weight:400; text-transform: uppercase;}
  .rbc-day-slot .rbc-events-container{ margin-right: 0px;}
  .rbc-today{ background:none;}
  .rbc-time-content > .rbc-day-slot{ background: $light-grayo1; border-radius:8px ; width: calc(100% - 10px); margin-right: 10px;}
  .rbc-toolbar{ display: none;}
  .rbc-allday-cell{ display: none;}
  .rbc-event-label{ font-weight: 500; text-align: center; width: 100%; font-size: 100%;}
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid $white;
    border: unset;
  }
  .rbc-current-time-indicator{ display:none;}
}

.set_fee_dialog{
  .dialog_title{ background: $body-bg; padding: 13px 30px;
  h4{ margin-top: 10px;}
  }

  .separator{ background:$db-saprator-bg; height: 1px; margin: 15px 0px 15px 0px;}  
}
.charge_fee_btn{ float: left; 
  &.charge_fee {
    height: 40px;
  }
}
.wrapper .schedule_select{ display: inline-block; vertical-align: top; margin-right: 10px;
  .select_control{background: url(./assets/images/icons/ic_date_range.svg) no-repeat 15px center; width: 162px;  padding: 0px 0px ; height: 40px; border-radius: $theme-radius; border: 1px solid $border-color;
    .MuiInputBase-root{ width: 149px;}
    .MuiSelect-select{ background: url(./assets/images/icons/ic_select_caret.svg) no-repeat 100% center; width: 149px; height:28px; line-height: 30px; border-radius: $theme-radius; font-size: 12px; font-weight: 400; color: $theme-secondary; padding: 6px 24px 6px 40px;}
    .MuiInput-underline:before{ border-bottom: none ;}
    .Mui-focused.MuiInput-underline:after{ border-bottom: none ;}
    .MuiSelect-icon{ display: none;}
  }
}

.wrapper .cal_card_state{
  .MuiCardContent-root{ padding: 0px ;
  .title_row{ border-bottom: 2px solid $body-bg; padding: 15px 20px;
  .outline_btn{ font-size: 12px; font-weight: 400; height: 40px;
  img{ margin-right: 8px;}
  }
  }

  }

}
.time_zone_info{ font-size: 14px; font-weight:500; color: $gray-text-label;
span{ font-weight: 400;}
}

.edit_holiday{ text-align: left;
  .label_input{
    .form_control{margin-bottom: 17px;
      .MuiInputBase-input{ padding: 5px 10px; min-height: 28px; font-size: 12px; font-weight: 400; border: 1px solid $gray-c-color; border-radius: $theme-radius;}
      .date{ background: url(./assets/images/icons/ic_date_range.svg) no-repeat 90% center;}
      .MuiOutlinedInput-notchedOutline{ border: none;}
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{ border: none;}
    }
  }
  h4{ margin: 0px 0px 20px 0px; font-size: 20px; color: $black; font-weight: 400;}
  h5{margin: 0px 0px 20px 0px; font-size: 14px; color: $text-color; font-weight: 500;}
  .setting_state .status_list{ min-height: 40px; margin-bottom: 17px;}
  .row_list{ font-size: 12px; line-height: 32px;
  &.holiday{ color: $black;}  
  &.status_list{font-size: 12px; text-decoration: underline; color:$theme-secondary; cursor: pointer;
  &:hover{ text-decoration: none;}
  }
  }
}

.map_action_row{ margin-bottom: 10px; margin-top: -20px; }

.map_legend{ display: inline-block; margin-left:2px; background: $white; font-size: 14px; font-weight: 400; color: $black; padding: 6px 15px;
  img{ display: inline-block; vertical-align: middle; margin-right:8px; margin-top: -3px;}
  .MuiCheckbox-root{ padding: 0px; margin-right: 10px; margin-top: -2px; color: $theme-secondary; }
  .MuiCheckbox-colorPrimary.Mui-checked{ color: $theme-secondary;}
}

.vehicle_location{
 .depot_information .depot_info .depot_name{ font-size: 22px; font-weight: 500;}
 .stat_progress .bar:after{ display: none;}
 .soc_value .soc_col .result{ display: inline-block;}
 .soc_level .text_class{ color: $black;}

}

.dialog_container .select_control , .wrapper .select_control{ min-width: 100% ; padding: 0px 10px ; height: 34px; border-radius: $theme-radius; border: 1px solid $border-color ;
  .MuiSelect-select{font-size: 12px ; color: $text-color ; line-height: 22px; background: url(./assets/images/icons/ic_select_caret.svg) no-repeat 100% center ; }
  .MuiInput-formControl.MuiInput-underline:before{ border: none ;}
  .MuiSelect-icon{ display: none;}
  .MuiMenuItem-root{font-size: 12px ;}
}

.select_location .select_control{min-width:220px ; background: $white url(./assets/images/icons/ic_directions.svg) no-repeat 10px center ; border: none ; 
.low_font{ margin-left: 5px; color: $low-placecolor;}
}
.optionlist_dest{ font-size: 12px ; 
  .low_font{ margin-left: 5px; color: $low-placecolor;}
  }
.select_location .select_control .MuiSelect-select{ padding-left: 30px;}
.route_map{ height: 100vh;}
.license_img{ margin-top: 10px;}
.information_section .info_detail.licence_info .tb_info_section .result .label{ width: 120px;}
.dialog_container .trip_details .h_charge_list .label{ width: 48%;} 
.dialog_container .trip_details .h_charge_list .result{ width: 50%;} 
.wrapper .driver_schedule{ margin-top: 8px;
  .label{ font-size: 14px; font-weight: 500; color: $black; text-align: left; line-height: 38px;}
  .select_control{ min-width: 90% ; text-align: left; height: 38px;}
  .driver_schedule_list{ margin-top: 17px;}
  .driver_schedule_list:first-child{ margin-top: 0px;}
  .driver_btn{ width: 90%; padding: 0px ; font-size: 13px ;}
  .error_msg{ color: $danger-red; font-size: 14px; margin-top: 20px; font-weight: 400;}
}
.set_schedule{
  .section_row{ padding:10px;}
  .select_control{ height: 40px;
    .MuiSelect-select{ min-height: 25px; line-height: 27px;}
  }
  h5{ font-size: 18px;}
  label{ font-size: 14px; color: $black; font-weight: 500; vertical-align: middle; line-height: 14px;}
  .set_consumption{ margin-top: 5px;
    .priority_label{ font-size: 14px; font-weight: 400; color: $text-color; margin-bottom: 15px;
      span{ display: inline-block; width: 15px; margin-right: 10px; margin-top:-3px; height: 15px; border-radius:3px; vertical-align: middle;
      &.priority_1{ background-color:$charging-bg-dark;}
      &.priority_2{ background-color:$priority-2;}
      &.priority_3{ background-color:$priority-3;}
      &.others{ background-color: $other-priority;}
      }
    }
    .add-remove_circle{text-align: right; 
        a{ margin-left: 20px; display: inline-block; width: 20px; height: 20px; margin-bottom: 15px;}
    }
  }
}
.station_details .information_section .info_detail .tb_info_section .row.set_priority .label{ width: 148px;}
.station_details .information_section .info_detail .tb_info_section .row.set_priority .result{
  width: calc(100% - 148px);
  .select_control{ min-width: calc(100% - 60px) ; height: 40px; vertical-align: middle;
    .MuiSelect-select{ font-weight: 500; color: $theme-secondary ; line-height: 30px;}
  }
  .btn_white.blue_bg{ min-width: inherit;}
}

.des_numb_icon_maps {
  width: 22px; height: 26px; background: url(./assets/images/icons/ic_beenhere.svg) no-repeat center center;
  color: white; text-align: center;
    span{ color: $white; font-size: 12px; font-weight: 500; text-align: center; display: block; line-height: 22px;}
}

.vehicle_icon_maps {
  width: 67.999px; height: 69px;  background: url(./assets/images/icons/vehicle_current_location.svg) no-repeat center center;
  text-align: center;
  span{ color: $theme-secondary; font-size: 11px;
    padding-top:25%;
    font-weight: 500; text-align: center; display: block; line-height: 22px;
  }
}

.vehicle_icon_dynamic_maps {
  width: 67.999px; height: 69px;  background: url(./assets/images/icons/vehicle_current_location_blank.svg) no-repeat center center;
  text-align: center;
  span{ color: $theme-secondary; font-size: 11px;
    padding-top:2px;
    font-weight: 500; text-align: center; display: block; line-height: 22px;
  }
}

.view_stop{ display: inline-block; margin-right: 10px; min-width: 150px; vertical-align: middle;
  .select_control{ background: $white; text-align: left; height: 37px;}
}

.msg_badge{
  .MuiBadge-badge{ background: $badge_color; font-size: 10px; right: 0px; top: 13px; border-radius: 3px; height: 20px;  min-width: 18px;}
}
.vehicle_analytics{
  .btn_white.blue_bg{ margin-left: 0px ;}
  .title_row{ margin-bottom: 25px;
    h4{ font-size: 18px; font-weight: 500; color: $black; line-height: 24px; margin: 0px;}
    .sub_heading{ font-size: 12px; font-weight: 400; color: $black;}
  }
  .graph_capt{ text-align: left; font-size: 14px; font-weight: 500; line-height: 24px; color: $black;}
  .card_legend{ margin-top: 20px; padding-top: 10px; border-top: 1px solid $gray-c-color; 
  .legend_item{font-size: 11px; font-weight: 400; margin-bottom: 2px;
    img{ margin-right: 5px;}
  &.available{ color:$theme-secondary;}
  &.transit{ color: $theme-secondary;}
  &.parked{ color:$parked-border;}
  &.charging{ color: $n-charging;}
  &.in-service{ color:$in-service;}
  &.complete{ color:$parked-border;}
  &.connected{ color:$n-charging;}
  &.offline{ color:$offline-border;}
  &.error{ color: $in-service ;}
  &.reserved{ color: $theme-secondary ;}
   
  }
  }

  .efficiency_bar{
    &.five_day_status{
      .bar{ background: none ;
      span{height: 10px; border-radius: 5px; display: block; margin-left: 1px;
      &:first-child{ margin-left: 0px;}
      &.transit{ background:$theme-secondary;}
      &.parked{ background:$parked-border;}
      &.charging{ background:$parked-border;}
      &.in-service{ background: $charging-bg-dark ;}
      &.complete{ background: $charging-bg-dark;}
      &.connected{ background: $in-service ;}
      &.offline{ background:$offline-border ;}
      
      }
      }
    }
    .MuiList-root{ padding: 0px;
      .MuiListItem-root{ margin-top: 20px; padding: 0px; font-size: 12px; font-weight: 500; color: $black; display: block;
        &:first-child{ margin-top:0px;}
      .date{ text-align: left;}
      .time{ text-align: right;}
      .bar{ height: 10px; border-radius: 5px; display: block; margin-top: 5px;
        background: linear-gradient(90deg, rgba(101,168,68,1) 0%, rgba(224,160,6,1) 50%, rgba(235,12,12,1) 100%);
        span{ position: relative; margin-top: 1px; width: 8px; height: 8px; display: inline-block; background: $white; border-radius: 5px;}
      }
      }
    }
  }

  .energy_detail{
    .MuiList-root .MuiListItem-root{ margin-top: 10px;}
    .port{ display: inline-block; vertical-align: middle; font-size: 12px; font-weight: 500; color: $black; width:63px;}
    .proress_bar{ display: inline-block; vertical-align: middle; position: relative;  width: calc(100% - 145px); height: 10px; border-radius: 3px; background: $border-color;
    span{ display: inline-block; height: 10px; background: $low-green; position: absolute; left: 0px; top:0px; border-radius: 3px;}
    }
    .current_kwh{ text-align: right; display: inline-block; vertical-align: middle; font-size: 12px; font-weight: 400; color:$text-color; width:80px;}
  }
}

.default_table .table .MuiDataGrid-row + .MuiDataGrid-row + .MuiDataGrid-row + .MuiDataGrid-row .alert_unread_mark .unread.dnone { background: none ;}
.defult_select_option{font-size: 12px; color: $text-color; line-height: 40px;}
.defult_form{
  .form_col{ margin-bottom: 20px; position: relative;
    &.radio_button{
        label{ margin-top: 0px; }
        label:first-child{ margin-left: 0px;
        .MuiRadio-root{ padding-left: 0px;}
        }
        .MuiRadio-colorPrimary.Mui-checked{ color: $theme-secondary;}
        .MuiSvgIcon-root{color: $theme-secondary;}
        .MuiFormControlLabel-label{font-size: 14px; color: $black; font-weight: 500;}
    }
    &.date_cal{
      .MuiTextField-root{ margin: 0px; border: 1px solid $gray-c-color; border-radius:5px;
        .MuiInput-underline:before{ border: none ;}
        .MuiIconButton-root{ color: $theme-secondary;}
      }
    }
    
    .error_msg{ position: absolute; color: $alert-marker; font-size: 10px; display: inline-block; vertical-align: middle; left: 155px; top: 42px;}
    &.error{ 
      .MuiFormControl-root  .MuiOutlinedInput-notchedOutline{ border-color: $alert-marker;}
    }
    &.checkbox{ margin-bottom: 10px;
      .MuiFormControlLabel-root{ margin-top: 0px; 
        margin-right: 0px;}
      .MuiFormControlLabel-label{ font-size:12px; font-weight: 400; color: $black; padding-left: 3px;}
      .MuiCheckbox-root{ padding: 0px 9px; color: $theme-secondary; }
      .MuiCheckbox-root.Mui-disabled {color: rgba(0, 0, 0, 0.26); }
    }
    &.space_set{
      &.checkbox{
        .MuiCheckbox-root{ padding: 0px;}
      }
    }
    .textarea{
      .MuiFormControl-root{ width: 100%;
        .MuiFormHelperText-contained{ text-align: right; margin-right: 0px; font-size: 10px;}
        .MuiOutlinedInput-multiline{ padding: 10px;
          .MuiOutlinedInput-input{ padding: 0px; height: inherit; max-height: 60px;}
        }
      }
    }
    .MuiSelect-select{ background: url(./assets/images/icons/ic_select_caret.svg) no-repeat 95% center; height: 40px; padding: 0px 10px;}
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{border-color: $gray-c-color;}
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border-color: $gray-c-color; border-width: 1px;}
    .MuiSelect-selectMenu{ font-size: 12px; color: $text-color; line-height: 40px;}
    
    .MuiSelect-icon{ display: none;}

    .MuiFormControl-root{ width: 100%;
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{ border-width: 1px;}
      .MuiOutlinedInput-notchedOutline{ border-color: $gray-c-color;}
      .MuiInputBase-input{ height:40px; padding: 0px 10px; font-size: 12px; color: $text-color;}
      
    }
    .disable{ background-color: $body-bg; cursor: none;}
    label{ font-size: 14px; font-weight:600; color: $black; display: inline-block; margin-top: 8px;
      .sub_label{ font-size: 10px; color: $gray-text-label; line-height: 16px; font-weight: 400; display: block;}
    }
    
  }  
  .MuiDivider-root{ margin: 6px 0px 20px 0px;}
  h3{ font-size: 18px; font-weight:500; color: $black; margin-bottom: 10px;}
  h5{ font-size: 14px; font-weight:500; color: $black; margin-bottom: 10px; text-align: left;}
}

.dialog_container.edit_detail_section.large_map_popup{
  .MuiDialog-container .MuiDialog-paperWidthSm{ min-width: 90%; max-width:90% ; max-height: 98vh ;}
  .MuiDialogContent-root {width: 100%; box-sizing: border-box;}
  .map_section.map_height280{height: 750px;}
  .dialog_title { padding: 13px 30px 50px;}
}

.edit_detail_section{
  &.report_height{ 
    .MuiDialogContent-root{padding: 20px 24px}

  }
  .dialog_title{ background: $body-bg; padding: 13px 30px;
  h4{ margin-top: 10px;}
  
  }
  .defult_form .form_col .single_line label{ width: 155px;}
  
  .map_section{ height:190px; width: calc(100% - 155px); overflow: hidden; border-radius: 5px; display: inline-block; vertical-align: top;
  
  }
  .separator{ background:$db-saprator-bg; height: 1px; margin: 15px 0px 15px 0px;}  
  .MuiDialog-container .MuiDialog-paperWidthSm{ min-width: 850px; max-width:850px ; max-height: 98vh ;}
  .MuiDialogContent-dividers{ padding: 16px 18px;}
  .section_group{ padding: 0px 13px;}
  .checkbox{ 
    .single_line{
    .MuiFormControlLabel-root{width: calc(100% - 155px);  margin-right: 0px;}
  }
  }
  .single_line{
   
    .MuiGrid-item .MuiFormControl-root{ width:calc(100% - 155px);
     .MuiInputBase-root{ width: 100%;}
    }
    .MuiInputBase-root{ width:calc(100% - 155px);}
    .MuiSelect-select{width:100%; padding: 0px 10px;}
    .defult_form .form_col .textarea .MuiFormControl-root{ width: calc(100% - 155px);}
  }
  .block_label{
    .error_msg{left: 0px; top:72px}
  }
  .time_schedule{
    .MuiOutlinedInput-root{ width:97px; margin-right: 0px;}
    .MuiOutlinedInput-root + .MuiOutlinedInput-root{margin-right:0px; float: right;}
   
  }

  &.sm{
    .MuiDialog-container .MuiDialog-paperWidthSm{ min-width: 625px; max-width:625px; max-height: 98vh;}
    .defult_form .form_col .single_line label{ width: 170px;}
    .single_line .MuiInputBase-root{width: calc(100% - 170px);}
    .defult_form .form_col .error_msg{ left: 170px;}
    .single_line .MuiFormControl-root .MuiInputBase-root{ width: 100%;}
    .single_line .MuiFormControl-root{width: calc(100% - 170px) ;}
  }

  &.md{
    .MuiDialog-container .MuiDialog-paperWidthSm{ min-width: 725px; max-width:725px; max-height: 98vh;}
    h5{ margin-bottom: 40px;}
  }
  
  .permission{ padding-top:20px;
    legend{ font-size: 14px; font-weight:500; color: $black; margin-bottom: 20px; }
  }

}
.blink-account{text-align: center; margin:50px auto;
  .form_box{ margin-bottom:40px; min-height: 80px;}
  .blink-image{margin-bottom: 25px;}
  .MuiInput-underline:after{display: none;}
  .MuiCard-root{max-width: 700px;box-shadow:none;box-sizing: borderbox; border-radius: 10px;-webkit-border-radius: 10px;
    .MuiCardContent-root {
      padding: 20px 30px 40px;
      h2{font-size: 24px; font-weight: 500;  margin:10px 0px 25px 0px;}
      h4{font-size: 16px;line-height:20px; font-weight: 400;  margin: 0 0 28px 0;}
     }
     .MuiFormGroup-root{float: left;}
    .MuiButton-text.active{background:$theme;color:$white;}
    .text_login {font-size:14px;font-weight:400;background:none;text-align: left;color:$theme;display: inherit;text-transform: inherit; margin: 50px 0 0 30px;} 

  }
  .section_group{
      .form_col-12{
        input[type="text"] + div .password_view_icon span{color: $theme;}
        .password_view_icon{ margin-right: 0px; padding: 0px;
        }
      
      
        .check-label{
          .MuiTypography-body1{ margin-top: -6px;}
        }
      }
      .MuiCheckbox-colorSecondary.Mui-checked {color:$theme-secondary; background: none;}
      .form-text{float: left; margin-top: 6px; text-align: left; line-height: 20px; width: 100%; font-size: 12px; font-weight: 300;
        span{color: $theme; cursor: pointer; font-weight: 400;}
        .MuiFormGroup-root{float: left; margin: -6px 0 0 0;}
      }
      .PrivateSwitchBase-root-15 {padding: 2px 9px 9px 9px;}
      .show-password{font-size: 14px;color: $offline-border;font-weight: 400;background: none; box-shadow: none;
          text-transform: capitalize;float:right;padding: 0;margin: 3px 0 0 0;}
       svg.MuiSvgIcon-root{width: 22px;}
       .MuiFormControl-root{width: 100%;margin-bottom:20px;
        &:hover{border-color: $gray-text1;
      }
      .MuiInputLabel-formControl {top: 12px;left: 11px;position: absolute;transform: none;font-size: 16px;
          font-weight: 400;color: $gray-text1;text-align: left;
      }
      label.MuiFormLabel-root.Mui-focused {display: none;}
      label + .MuiInput-formControl {margin-top: 0;}
     .MuiInput-underline{
       font-size: 16px; font-weight: 400; color:$border-color-style;border-radius:8px;
       &:before{display:none;} 
      }
      .MuiInputBase-root { border: 1px solid $gray-text1;padding: 4px 10px 3px; border-radius: 6px;
      &:hover{border-color: $theme;}
      }
      .MuiInput-underline.Mui-focused{ border-color:$theme;
      } 
      }
      .listing-items{
          .MuiListSubheader-sticky { top: 0;z-index: 1;position: sticky;background-color: inherit;padding: 0;
              line-height: 18px;text-align: left;color: $black;font-size: 16px;font-weight: 500;
          }
          .MuiList-padding { padding: 0px 0 0 35px;}
          .MuiListItem-gutters{margin-top:8px;padding:0;font-size: 14px;color: $placholder-color; font-weight: 400;
          .circle{margin: 0 8px 0 0;width: 8px; height: 8px; border-radius: 50%; -webkit-border-radius: 50%; background: $gray-text1;}
          .circle.active{background: $theme;} 
          }
      }
      .error{
          position: relative; 
          .MuiFormControl-root .MuiInputBase-root{ border-color:$alert-marker;}
          .error-text{position: absolute; left: 0; bottom: 4px; font-size: 10px; color: $alert-marker; font-weight: 400;}
          .MuiInput-underline.Mui-focused{ border-color:$alert-marker;
            .password_view_icon{ 
              span{ color: $low-placecolor;}
              }
          }
      }  
      }
      &.log-in{
          .small_card{ width: 386px;}
          .card_content{ padding: 30px;}
          .form_box{ margin-bottom:40px; min-height: 80px;}
          .action_text{ margin-top:5px;}
      }
      &.email-look{
        .MuiCard-root .MuiCardContent-root h2{ margin-bottom: 0px;}
        p{text-align: left; font-size: 14px; color: $black; line-height: 20px; margin-top: 20px;}
        .block_btn{ margin-bottom: 0px;}
      }
      &.resend-confirmation{
        p{text-align: left; font-size: 14px; color: $black; line-height: 20px; margin-bottom: 30px;}
      }
  }

  .user_groups_status{
    .depot_state span{ margin-bottom: 10px;}
  }

  .zone_location_label{ line-height: 22px; white-space: normal;
    span{ background: $progress_bar_bg; height:25px; line-height:19px; margin:5px 10px 5px 10px; border-radius:3px; font-size: 12px; color: $theme-secondary; padding: 4px 10px; display: inline-block;}
  }
  .wrapper .zone_location-table{
    .MuiDataGrid-dataContainer{
        .MuiDataGrid-viewport{ max-height: inherit;}
    }
  }
  .dialog_container .information_section .zone_section_border{border:1px solid $gray-text1; border-radius: $theme-radius; padding:20px 20px 0px 20px; margin:20px 13px; margin-top: 0px;
  .zone_section{ margin-bottom: 0px;
  &.zone_sec_det{ margin-top: 0px;
  .title_row h2{ color: $black; text-transform: capitalize;
  &.max_w{width: 85%;}
  }
  }
  }
  .defult_form { float: right;
  .form_col.checkbox .MuiCheckbox-root{ padding: 0px;}
  .form_col.checkbox{ margin-bottom: 0px;}
  }
  }
  .msg_text{ font-size: 16px; color: $black; margin:15px 0px;}
  .update_box{ margin-bottom: 20px; 
    .update_box_item{border: 1px solid $gray-text1; border-radius:$theme-radius; padding:10px 20px; min-height:89px;
      &.active_zone{ background: $light-grayo1; border-color: $theme-secondary;}
      .longlat{ margin-bottom: 5px;
        .longlat_row{ font-size: 12px; color: $black; }
      }
     h4{ margin:0px 0px 10px 0px; font-weight: 500; font-size:12px; color: $black;}
     .zone_detaile{ font-size: 14px; color: $text-color; font-weight:400;}
     .station_port{ font-size: 12px; color: $text-color; font-weight:400;
      span{ margin-right: 20px;}
    }
    }
  }  
  
 
.hide{display:none}
.dialog_container.chargesession_detail .MuiDialog-paperWidthSm {
  max-height: 90vh;
}
.chargesession_detail{
  .position_fixed_nav{ box-shadow: none;}
   h3{margin: 0 0 10px 0px; font-size: 22px; color: $black; font-weight: 500;}
   h4{margin: 0 0 13px 0px; font-size: 18px; color: $black; font-weight: 500;}
    .price{margin: 10px 0 10px 20px;}
    .history_charging_list {
      border-top: none;padding: 40px 30px 30px 30px;
  }
  .MuiTabs-indicator{display: none;}
     .dialog_title{border-bottom: 1px solid $offline-bg;}
     .chargesession_deatiltab{margin: 0 -16px;
      .table_scroll .table_footer{margin-left: 20px;}
       .MuiTabs-flexContainer{ border-bottom: none; padding-left: 15px;}
      .MuiAppBar-colorDefault{
        background: $white; font-weight: 400;
        .MuiTab-root {
          text-transform: capitalize; font-weight:400;border-bottom: 5px solid $white;
          color:$black;
          opacity: 1; min-width: inherit; padding-left: 0px; padding-right: 0px; margin-right: 40px;
      }
      .Mui-selected{background:$white; font-weight:500; color: $black; border-bottom: 5px solid $theme-secondary;}
     }
     .list-detail{
      
        .MuiListItem-root{display: block; padding: 0 0 15px 0;
          label{ font-size: 12px; font-weight: 400; color: $gray-text-label; display: block;}
          span{ font-size: 16px; font-weight: 400; color:$black;}
  }
  }
}
.pricedetail-card{ padding: 0 20px; margin-bottom: 20px; background: $charge-tab_bg; border: none; border-radius: 5px; -webkit-border-radius: 5px;}

}
.common-date-picker{
  display: inherit;
  .MuiFormControl-root{
      float: left;margin: 0 20px 0 0;width:193px;
      .MuiOutlinedInput-root{ font-size: 12px; line-height: 18px; color:$border-color-style;border-radius: 5px;}
      .MuiOutlinedInput-notchedOutline{border-color: $gray-c-color;}
      .MuiOutlinedInput-input {
          padding: 16.5px 14px;
      }
  }
  .mui-focused{border-width: 1px;}
}
.picker-error{ margin-top: 30px; font-size: 13px;}

.user_name-dark-blue {
  background-color:$userName-dark-blue
}
.user_name-sky-blue {
  background-color:$userName-sky-blue
}

.user_name-opaq-green {
  background-color:$userName-opaq-green
}

.user_name-dark-green {
  background-color:$userName-dark-green
}

.user_name-ligh-green {
  background-color:$userName-ligh-green
}

.user_name-grey {
  background-color:$userName-grey
}
.user_name-dark-grey {
  background-color:$userName-dark-grey
}

.user_name-darkish-grey {
  background-color:$userName-darkish-grey
}


.information_section .zone_section.zone_section_border{ border-bottom: 1px solid $dark-divider;}
.information_section .info_detail:last-child .zone_section.zone_section_border{ border:none; margin-bottom: 0px;}

.wrapper .inside_page .information_section .zone_section .list_head.mb-20{ margin-bottom: 20px;}
.dialog_container .dialog_title .mt-10{ margin-top: 10px;}

.map_minheight240{ min-height: 240px;}
.map_height240{ height: 237px; border-radius: 6px; overflow: hidden;}
.dialog_container.edit_detail_section .map_section.map_height280{ height: 280px;}
.map_width {
  height: 200px;
  width: 330px;
}

.edit_detail_section.request-blinkcard {
  .MuiDialog-container .MuiDialog-paperWidthSm {
    min-width: 520px;
    max-width: 520px;
    max-height: 98vh;
  }
  .MuiSelect-select{ background: url(./assets/images/icons/ic_expand_more.svg) no-repeat 95% center; height: 40px; padding: 0px 10px;}
  .defult_form {
    .form_col {
      .single_line {
        .MuiGrid-item .MuiFormControl-root {
          width: calc(100% - 210px);
          .MuiInputBase-root {
            width: 100%;
          }
        }
        .MuiInputBase-root {
          width: calc(100% - 210px);
        }
        label {
          width: 200px;
        }
      }
    }
  }
}
.blinkcards {
  .button_row {
    margin: 6px 0px 21px 0px;
    button {
      border: none;
      cursor: pointer;
    }
  }
  .depot_details .depot_information .depot_location_map{width:60px; height: 60px;}
  .depot_information .depot_info .depot_name span {color: #000;font-size: 22px;font-weight: 500;}
}

.wrapper .blinkcards .position_fixed_nav {top:80px}

.edit_detail_section.blink-my-card{
  &.sm{
    .defult_form .top-grid .form_col{margin-bottom: 0;}
    .defult_form .top-grid span{color: $black; font-size: 14px; font-weight: 400;}
    .MuiDialog-container .MuiDialog-paperWidthSm{ min-width: 625px; max-width:625px; max-height: 98vh;}
    .defult_form .form_col .single_line label{ width: 170px;}
    .single_line .MuiInputBase-root{width: calc(100% - 230px);}
    .defult_form .form_col .error_msg{ left: 170px;}
    .single_line .MuiFormControl-root .MuiInputBase-root{ width: 100%;}
    .single_line .MuiFormControl-root{width: calc(100% - 230px) ;}
    .MuiSelect-select{ background: url(./assets/images/icons/ic_expand_more.svg) no-repeat 95% center; height: 40px; padding: 0px 10px;}
  }
}
 
.zone_scroll{ max-height: 600px; overflow-y: auto;}

.dialog_container.sm_330 {
  .MuiDialog-paperWidthSm {
      min-width:330px;
      max-width:330px;
      max-height: 98vh;
  }
}
.dialog_container.sm_450 {
  .MuiDialog-paperWidthSm {
      min-width:450px;
      max-width:450px;
      max-height: 98vh;
  }
}
.dialog_container .coming_soon{ color:$theme-secondary; font-size: 16px; margin: 0px 0px 35px 0px; font-weight: 700;}
.cursor_pointer{cursor: pointer;}

.training_detail{ padding: 20px 30px; background: $white; font-family: 'Open Sans', sans-serif;
  h1{font-size: 30px; line-height: 35px; position: relative; color:$training-detail-heading; font-weight: 600;
   span{padding-top:15px;display: block;font-size: 22px;line-height: 30px;font-weight: 400;color: $black;}
   cite{position:absolute;right:0;top:4px;font-style: normal; font-size: 16px;line-height: 21px; display:none;}
  }
  .training-background{padding:25px 0 0 15px;margin: 40px 0 55px;background: $body-bg;
    .step_images{width: 95%;}
    .training-content{padding-top: 20px;
      h2{font-size: 20px; line-height: 24px; color:$training-detail-heading;font-weight: 600;}
      p{font-size: 16px; line-height: 25px;padding: 0 30px 0 0; font-weight: 400;color: $black;
        strong{font-weight: 600;}
      }
    }
  }

}
.training_questions{ min-height: 173px; font-family: 'Open Sans', sans-serif;
  .training_questions_section + .training_questions_section{ margin-top: 50px;}
  h2{font-size: 20px; font-weight:600; color:$training-detail-heading; margin-top: 0px; font-family: 'Open Sans', sans-serif;}
  .questions_column{ background: $white; border-radius: 0px; box-shadow: none;min-height: 150px;
    .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight{ opacity: 0;}
    .MuiCardActionArea-roo{ background: none;}
    .MuiCardContent-root{ padding: 30px 20px;}
    h2{ font-size: 18px; color: $theme-secondary; font-weight: 600; margin-bottom: 13px;
    .subheading{ display: inline-block; width: calc(100% - 30px);}  
    .link_icon{ float: right; margin-top:-3px;}
    }
    p{ font-size:14px; font-weight:400; color:$welcome-back-content; line-height: 19px;}
  }
}

.user_menu_open .MuiMenu-paper{min-width: 350px; top:64px !important; background: none; box-shadow: none; padding: 0px 0px 20px 20px;
  .MuiMenu-list{ padding:0px; background: $white;
      box-shadow: 0px 5px 5px -3px $border-color-style; border-radius:6px ;
  }
  .MuiMenu-list:hover{ background: $white;}
  .MuiMenuItem-root{ padding: 0px;
    .MuiTouchRipple-root {
      display: none;
    }
  }
  .MuiMenuItem-root:hover{background: $white;}

  .user_detail_row{  width:100%;
  .user_img{ width: 70px; height:70px; border: 1px solid $border-color; border-radius: $theme-radius; margin-right: 10px; display: inline-block; vertical-align: top;
  img{width: 100%;}
  }
  .user_info{display: inline-block; vertical-align: top;
      .user_name{ font-size: 14px; font-weight: 500; color: $black;
      span{ font-size: 10px; font-weight: 400; color:$filter-text; display:block;}
      }
      .user_email{ font-size:12px; color: $black; font-weight: 400; margin-top: 10px;
      img{ vertical-align: middle; margin-right: 10px;}
      }
  }
  .link_list{ border-top: 1px solid #ccc; padding: 20px; font-size: 12px; font-weight: 400; color: $theme-secondary;
      img{ margin-right: 10px; vertical-align: middle;
      &.hover{ display: none;}
      }
      &:hover{ color: $theme;
      img{
        &.hover{ display: inline-block;}
        &.view{ display: none;}
      }
      }
  }
  }
  .user_detail_row_inside{padding: 20px; border-radius: 6px 0px 0px 0px; width: 100%; background:$white; cursor: context-menu;}
}

.form_result_sec .css-1wy0on6 {
    display: none;
}

.plain_text{ font-size: 14px; color: $black;
  &.mt-250{ margin-top: 150px;}
  &.mt-200{ margin-top:75px; margin-bottom: 75px;}
  }

.change_password{
  .blink-account{ border-top:$divider; padding-top: 40px; margin-top: 40px;}
  .user_img img{ border: $divider; border-radius: 6px;}
  .info_detail .tb_info_section .result .label{ width: 105px;}
}

.user_start_name{width: 70px; height: 70px; float: left; vertical-align: top; border-radius:5px; text-align: center;font-size: 60px; line-height: 70px;color:$white; font-weight: 400;
  &.topuser{width: 40px; height: 40px; line-height:42px; font-size: 30px;}
  &.green{background: $theme;}
  &.blue{background: $theme-secondary;}
  &.black{background: $black;}
  &.yellow{background: $user_yellow_bg;}
  &.orange{background: $user_orange_bg;}
}
.wrapper .error-page{ width: 386px;}
.wrapper .error-page .card_content{ padding: 40px 30px;}
.wrapper .error-page p{ font-size: 14px; text-align: left; margin-bottom: 30px;}
.wrapper .error-page .mail_link{ font-size: 14px; font-weight: 500; color: $theme-secondary; text-decoration: none;}
.wrapper .error-page .mail_link:hover{ color: $theme;}
.wrapper .error-page .block_btn{ margin-top: 30px;}

.avtar_div {
  width: 70px; height: 70px;
  img{width: 100%; height: 100%; border-radius: 5px; -webkit-border-radius: 5px;}
}
.dialog_container .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .dialog_container .MuiDataGrid-root .MuiDataGrid-cell:focus-within{outline:none;}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within{outline:none;}

.edit_detail_section.updated_pop_up{
  &.sm{
  .MuiDialog-container .MuiDialog-paperWidthSm{min-width: 520px; max-width: 520px; max-height: 98vh;}
  }
  .defult_form{
    .card_listing_detail{
      .MuiListItem-gutters{padding: 0 0 5px 0; font-size: 14px; line-height: 24px; color: $black; font-weight: 400;
      span{font-weight: 600; min-width: 160px; display: inline-block;}
      }
    }
    .content{font-size: 14px; line-height: 19px;color:$black}
    .form_col{margin-bottom: 5px;
      .single_line{
        label{width: 100%;}
        .MuiFormControl-root, .MuiInputBase-root { width: 100%;}
    }
    .error_msg {left: 0;bottom: -20px;top: auto;}
    .error_position{left: 137px; bottom: -14px;}
    
  }
}
}

.wrapper .fee_templeate{
  .small_card .card_content{ padding: 14px 20px;}
  .title_row{ margin-bottom: 30px;
      &.mb-15{ margin-bottom: 15px;}
      h2{ margin: 0px; font-size: 18px; font-weight: 500; text-transform: inherit; color: $black; }
  }
}

.charge_rate{ margin-top: 30px;
  .btn_white{min-width: 100px;}
}
.common_form{
.section_block{ margin-bottom: 50px; min-height: 65px;
&.second_level{
  &.mb-0{ margin-bottom: 0px;}
  .label{ font-weight: 400;}
  .fs_12{
      .label{ font-size: 12px;}
  }
  .result .form_control .input_text, .result .form_control .input_text .MuiInputBase-input{ text-align: left;}
}
}
.label{ font-size: 14px; font-weight:500; color: $black; margin-bottom: 7px;
  .sab_label{ font-size: 10px; font-weight: 400; display:block; color: $sublabeltext;}
  &.mn-34{min-height: 34px; margin-bottom:3px;}
}
.result{
  .form_control{ width: 100%;
    &.f_md{width:220px}
    &.f_sm{width:80px}
    &.gra_period .input_text{ text-align: left; padding: 10px 10px 10px 10px;
      .MuiInputBase-input{ text-align:left;}
    }
    .MuiOutlinedInput-root{ border-radius: $theme-radius; background: $white;}
    .MuiOutlinedInput-root.disable{ background: $body-bg;}
    .MuiSelect-selectMenu{  text-align: left; font-size: 12px; font-weight: 400; color: $text-color;}
    .MuiSelect-select{ background: url(./assets/images/icons/ic_expand_more.svg) no-repeat calc(100% - 10px) center; border: none; width:100%; height: 20px; padding: 10px; border-radius: $theme-radius; font-size: 12px; font-weight: 400; color: $text-color;}
    .MuiSelect-icon{ display: none;}
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{  border: 1px solid $gray-text1;}
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{ border: 1px solid $gray-text1;}

    .input_text{  text-align: right; font-size: 12px; font-weight: 400; color:$gray-text-label; width:80px; height: 40px; padding: 10px 20px 10px 10px;
      &.w_100per{width: 100%; text-align: left;
        .MuiInputBase-input{text-align: left;}
      }
      .MuiInputBase-input{ text-align: right; padding: 0px;}
      .MuiTypography-root{font-size: 12px; color:$low-placecolor}
      &.input_text_left{.MuiInputBase-input{ text-align: left; padding: 0px;}}
    }
  }
}
}

.edit_detail_section .single_line .MuiGrid-item .custom_auto_select{ position: relative; width: calc(100% - 155px); display: inline-block;
  .MuiFormControl-root{ width: 100%;}
  .auto_select_list {width: 100%; border: 1px solid $gray-text1; position: absolute; background: $white;  z-index: 999; padding: 10px; font-size: 14px; border-radius: 5px; box-shadow: 1px 2px 3px $gray-text1;
  h2{ font-size: 14px; margin: 0px 0px; color: $black; font-weight: 500;
  img{ vertical-align: middle; margin-right: 5px;}
  }
  address{ font-size: 11px; margin-left: 22px; font-style: normal;}
  .show-address{ margin-bottom: 10px;}
  }
  }

  .text{
    h2{ font-size: 16px; float: left; font-weight: 500; color:$theme-secondary; text-transform: uppercase; margin: 0px;}
  }

.dialog_container.edit_detail_section.inherit_width {
  &.width_400 {
    .MuiDialog-paperWidthSm {
      min-width: 400px;
      max-width: 500px;

      .paragraph {
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 24px;
        color: $black;
        font-weight: 400;

        &.blink_code {
          font-size: 20px;
          font-weight: 600;
          color: $black;
          margin: 10px 20px 0 0;
        }
      }
    }
  }
}
  

/*---------Responsive CSS Start--------------*/
@media screen and (max-width:1900px) {
  .training_questions .questions_column{min-height:190px}
}

@media screen and (max-width:1350px) {
.station_details .port_card .title_row h2{ font-size: 15px;}
.training_questions .questions_column{min-height:210px}
}
@media screen and (max-width:1295px) {
.depot_detail_head{
  .status{ margin-bottom: 10px; min-width: 100px;}
}
.scroll_effect .position_fixed_nav{ top:110px;}
.tab_menu_select{top:-30px}
}
@media screen and (max-width:1280px) {
  .vehicle_routes .select_alpha{ margin-left: 10px;}
  .depot_detail_head{
    .stat_list { margin-top: 10px;}
    .status{ margin-bottom: 10px;  min-width: 100px;}
  }
  .scroll_effect {
    .depot_detail_head{
      .stat_list { margin-top: 0px;}
    }
  }
  .training_questions .questions_column{min-height:190px}
  
  }
@media screen and (max-width:1260px) {
  .depot_state{
    .state{ margin: 14px 10px 0px 10px;}
  }  
 .vehicle_g_status .recent_alert_list ul li .time_detail{ font-size: 14px;}
   
}

@media screen and (max-width:1210px) {
.scroll_effect .depot_information{ height: inherit }
.scroll_effect .position_fixed_nav{ top:143px;}
.depot_detail_head .stat_list { margin-top: 10px; text-align: left; background: $body-bg; float: none;}
.scroll_effect .station_details .position_fixed_nav{ top:105px;}
.vehicle_g_status .recent_alert_list ul li .time_detail{ font-size: 11px;}
.vehicle_g_status .recent_alert_list ul li .vehicle_number{ font-size: 12px;}
}

@media screen and (max-width:1160px) {
  .view_stop{ min-width: 100px;}
  .depot_state{
    .state{ margin: 14px 5px 0px 5px;}
  } 

  .information_section .info_detail .tb_info_section .result .label{ width: 100%;}
  .information_section .info_detail .tb_info_section .result .result{ width: 100%;}

  .information_section .info_detail .tb_info_section.hours .result .label{ width:92px;}
  .information_section .info_detail .tb_info_section.hours .result .result{width: calc(100% - 140px);}
  
.dialog_container.dialog_container_center .MuiDialog-paperWidthSm{ min-width: 90%; max-width:90%; max-height: 98vh;}
    
}

@media screen and (max-width:1100px) {
.station_details .port_card .title_row h2{ font-size: 12px;}
.station_details .information_section .info_detail .tb_info_section .row.set_priority .result{ width: 100%;}
.station_details .information_section .info_detail .tb_info_section .row .label{ display: block;}
.station_details .information_section .info_detail .tb_info_section .row .result{ display: block; width: 100%;}
.station_details .information_section .info_detail.depotstation_infolist .tb_info_section .row .label{width: 190px; display: inline-block; }
.station_details .information_section .info_detail.depotstation_infolist .tb_info_section .row .result{ display: inline-block; width: calc(100% - 190px);}
.map_legend{ font-size: 11px;}
.view_stop{ margin-right: 4px;}
.select_location .select_control{ min-width: 200px;}
}
@media screen and (max-width:1070px) {
  .depot_state{
    .state{ margin: 14px 5px 0px 5px;
      span{width: 50px;}
    }
  }  
  .dialog_container.dialog_container_center .MuiDialog-paperWidthSm{ min-width: 98%; max-width:98%; max-height: 98vh;}

}

@media screen and (max-width:1060px) {
.fleet_card_inside{ 
  .fleet_title{ font-size: 16px; }
}
}
@media screen and (max-width:1024px) {
  .depot_detail_head{
    .status{ margin-left:5px; }
  }
  }

@media screen and (max-width:1052px) {
  .depot_list{
    .stat_list {
      .status{ margin-bottom: 10px; margin-right: 5px;} 
    }
  }
}
@media screen and (max-width:1000px) {
  .wrapper .fleet_card_inside{ 
    .fleet_title{ font-size: 15px; }
  }
  }

@media screen and (max-width:950px) {
.station_details .information_section .info_detail .tb_info_section .row .label{ display: inline-block;}
.station_details .information_section .info_detail .tb_info_section .row .result{ display: inline-block; width: inherit;}
} 
  
.image-gallery-fullscreen-button {display:none}

/*--------Sort CSS-----*/
.img_res{ max-width: 100%; min-width: 100%;}
.text_right{text-align: right;}
.va_middle{vertical-align: middle;}
.text_left{ text-align: left;}
.text_center{ text-align: center;}
.p_0{ padding: 0px ;}
.p_tb_0{ padding-top: 0px; padding-bottom: 0px;}
.p-b-0{ padding-bottom: 0px;}
.d-inline-b{ display: inline-block; vertical-align: top;}
.mt-3{ margin-top: 3px;}
.wrapper .inside_page .mt-13{ margin-top: 13px ;}
.wrapper .inside_page .mt-16{ margin-top: 16px;}
.full_right{ float: right;}
.wrapper .inside_page .ml_0{ margin-left: 0px;}
.dialog_container.dialog_container_center .ml_0{ margin-left: 0px;}
.pl_pr_5{ padding-left: 5px ; padding-right: 5px ; }
.mt-0{ margin-top: 0px;}
.mb-10{ margin-bottom: 10px;}
.wrapper .inside_page .mb-20{ margin-bottom: 20px;}
.mt-5{ margin-top: 5px;}
.mt-10{ margin-top: 10px;}
.mt-20{ margin-top: 20px ;}
.mt-30{ margin-top: 30px ;}
.mt-40{ margin-top: 50px ;}
.no-border{ border: none }
.pt-10{ padding-top: 10px;}
.pb-10{ padding-bottom: 10px;}
.wrapper .inside_page .p_t_0{ padding-top: 0px;}
.dialog_container .MuiDialog-paper .p_t_0{ padding-top: 0px;}
.material-icons-outlined {
  font-family: $material-font;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'liga';
}
.wrapper .inside_page .w_100per{width: 100% ;}
.dialog_container .MuiDialog-paper .w_100per{width: 100% ;}
.min-w-i{ min-width: inherit;}
.d-none{ display: none;}
.mr-5{ margin-right: 5px;}
.wrapper .mr_10{ margin-right: 10px;}
.d-in-b{ display: inline-block; vertical-align: top;}
.pl_5{ padding-left: 5px;}
.disable_all{opacity: 0.5;}
.mr-40{ margin-right: 40px;}
.mr-10{ margin-right: 10px;}
.wrapper .inside_page .m_w_157{min-width: 157px;}
.welcome-back .saved_section .pt_0{ padding-top: 0px;}
.wrapper .inside_page .welcome-back .pt_0{ padding-top: 0px;}
.wrapper .inside_page .pt_5{ padding-top: 5px;}
.wrapper .inside_page .p_tb_0{ padding-top: 0px; padding-bottom: 0px;}
.no_bg{ background: none;}
.mt-min-5{ margin-top:-5px}

/*------Font Family Css End--------*/

/*-----Using to remove Google Text on Map------*/
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}
.gmnoprint a, .gmnoprint span, .gm-style-cc {display:none;}
.dialog_container .pt_30{ padding-top: 30px;}
.full_w_155{width:calc(100% - 155px); display: inline-block;}
/*------Font Family Css End--------*/

.polygon_zone_map_height{ height: 400px;}
.zone_map_height{ height: 228px; width: 228px;} 


.form_control.placeholder_opacity .input_text .MuiInputBase-input::-webkit-input-placeholder{ opacity: 1;}

.form_control.placeholder_opacity .input_text .MuiInputBase-input::placeholder{ opacity: 1;}

/* To hide input type number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.error_prompt_msg_sm{color: $danger-red;font-size: 12px; font-weight: 400;} 

