@import "../../../src/App.scss";

.fleet_information {
    display: inline-block;vertical-align: top;
    .fleet_info {
        display: inline-block;vertical-align: top;
        .fleet_group {
            font-size: 22px;font-weight: 400;color: $black;line-height: 22px;margin-bottom: 3px;
            span {
                color: $offline-border;
            }
        }
        .group_addr {
            font-size: 17px;font-weight: 400;color: $text-color;line-height: 17px;margin-bottom: 10px;margin-top: 10px;
        }
        .group_nav {
            margin-top: 10px; color: $theme-secondary; cursor: pointer;
        }
        .group_details {
            font-size: 12px;font-weight: 400;color: $text-color;line-height: 14px;
        }
    }
}

.delete_message_padding{padding:0 7px;}

.fleet-warning-msg{
  font-size: 12px;
  color: $sublabeltext;
}

.fleet_span {
    display: inline-block;vertical-align: middle;font-size: 12px;font-weight: 500;color: $text-color;width: 150px;
}

.col_disable {
    pointer-events: none;
}

.fleet-error-desc {
    color:$ter_status;text-align: center;font-weight: 500;
}

/*--------constraints tab-------*/
.chargers {
  .p-20 {padding: 20px;}
  .assign_stations{
    min-height: 27vh;
    padding-bottom: 20px;
    .default_table {
      .MuiCheckbox-root {
        color: $theme-secondary;
      }
      .MuiDataGrid-row {
        background-color: $white;
        border-bottom: 1px solid $gray-text1;
        .MuiDataGrid-cell {border-bottom: 1px solid $gray-text1;}
      }
      .MuiDataGrid-row:last-child {
        border-bottom: none;
        .MuiDataGrid-cell {border-bottom: none;}
      }
      .MuiDataGrid-row:hover {
        background-color: $body-bg;
        .MuiDataGrid-cell {border-bottom: 1px solid $gray-text1;}
      }
      .MuiDataGrid-cell .line-height {
        line-height: 20px;
      }
      .fleet-warning-msg {
        font-size: 10px;
        width:150px;
        word-wrap: break-word;
      }
      .MuiCircularProgress-colorPrimary {left: 0;}
    }
  }
  .hide_select_all .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {display: none;}
  .small_loader {
    position: relative;
    margin-top: 4vh;
    width: 25px !important;
    height: 25px !important;
  }
}


/*--------constraints tab-------*/
.constrains {
    .fleet_error_msg{ position: absolute; color: $alert-marker; font-size: 10px; display: inline-block; vertical-align: middle; top: 39px;}
    .text_font {font-size: 12px;color:$text-color; .custom_line_height {line-height: 40px;}}
    .font_split {font-size: 12px;font-weight: 600;padding-top:5px;}
    .bottom_border_split {border-bottom: 1px solid $gray-text1 ; }
    .common_form .result .form_control .input_text {width: 120px;}
    .schedule_container .common_form{
        overflow-x: auto;
        .default_form {min-width: 1162px;}
    }
    .btn_size {width: 100px;}
    .dropdown_width {width: 100px;}
    .common_form .result .form_control.f_md {width: 120px;}
    .bottom_border {border-bottom: 1px solid $gray-text1 ;}
    .bottom_border:last-child {border-bottom: 0;}      
    .mt-15 {margin-top: 15px;}
    .mt-20 {margin-top: 20px;}
    .mt-21 {margin-top: 21px;}  
    .ml-30 {margin-left: 30px;}
    .ml-40 {margin-left: 40px;}
    .ml-60 {margin-left: 60px;}
    .srv-validation-message {font-style: normal;font-weight: 400;line-height: 10px;}
    @media screen and (max-width:1520px) {
      .mr_dayindex{margin-right: -45px}  
    }
    @media screen and (min-width:1520px) and (max-width:1580px) {
      .mr_dayindex{margin-right: -55px}  
    }
    @media screen and (min-width:1580px) and (max-width:1600px) {
      .mr_dayindex{margin-right: -67px}  
    } 
    @media screen and (min-width:1600px) {
      .mr_dayindex{margin-right: -105px}  
    } 
}
.input_adornment_font{
    font-size: 12px ;
}
.information_section .result {
  font-size: 14px;
  font-weight: 400;
  padding-top: 3px;
}
.form_col_alert{ margin-bottom: 12px; position: relative;
    &.radio_button_alert{
        label{ margin-top: -9px; }
        label:first-child{ margin-left: 0px;
        .MuiRadio-root{ padding-left: 0px;}
        }
        .MuiRadio-colorPrimary.Mui-checked{ color: $theme-secondary;}
        .MuiSvgIcon-root{color: $theme-secondary;}
        .MuiFormControlLabel-label{font-size: 14px; color: $black; font-weight: 500;}
    }
  }

  .custom_sub_header {font-weight: 600;}
  .custom_header_margin {margin-bottom: 25px;}

  .text-terminate {
    color: $black;
    font-size: 16px;
    line-height: 21px;
    margin: 20px 0 10px;
}



.error-show {
  border: 1px solid red;
  border-radius: 4.8px;
}

.edit_detail_section.sm .defult_form .form_col .form_col-6 .error_position {
  left: 280px;
}

.notification_label {
  width: 226px;
  vertical-align: top;
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  color: #000000;
  display: inline-block;
}

.alert_dialog .MuiDialogContent-root {
  padding: 18px 30px;
}

label{
  font-weight: 700;
  font-size: 12px;
  color: $black;
}

.input_text_300{
    width: 300px;
    height:40px;
}

/*----------------------------------------------------------Schedule Constraint Edit Dialog Box----------------------------------------------------*/
.dialog_container_fleet {
  &.dialog_container_center_fleet {
    .fleet_error_msg{ position: absolute; color: $alert-marker; font-size: 10px; display: inline-block; vertical-align: middle; top: 39px; margin-bottom: 20px;}
    .text_font {font-size: 12px;color:$text-color;}
     .MuiDialog-container {
          align-items: center;
          .MuiDialogContent-root {
              padding: 0;
              .border_bottom {
                  border-bottom: 1px solid $border-color;
                  .bottom_border:last-child {border-bottom: 0;}
              }
          }
      }
      .MuiPaper-rounded {
          border-radius: 5px;
      }
      .MuiDialog-paperWidthSm {
          min-width: 1115px;
          max-width: 1115px;
          max-height: 98vh;
      }
  }
  .MuiGrid-align-items-xs-flex-start {
    align-items: flex-start;
    padding-bottom: 0px;
    margin-left: 20px;
}
   .margin-left-20{
    margin-left: 20px;
   }

   .margin-left-30{
    margin-left: 40px;
   }
   .padding-left-returnplug{padding-left:17px}
   .padding-left-onreturn{padding-left:5px}
   .padding-left-endtime{padding-left:7px}
   label{
    font-weight: 700;
    font-size: 12px;
    color: $black;
  }
  .dialog_title {
    background: $body-bg;
    padding: 13px 30px;
    h4 {
        margin: 0px;
        font-size: 20px;
        font-weight: 700;
        color: $black;
        display: inline-block;
        margin-top: 5px;
    }
}
  .MuiDialog-container {
      align-items: center;
  }
  .MuiDialog-paperWidthSm {
      min-width: 840px;
      max-width: 840px;
      max-height: 98vh;
  }
  .MuiDialog-paper {
      margin-bottom: 0px;
      .form_col .single_line .w_100per.full_w {
          width: 100%;
      }
  }
  .MuiPaper-rounded {
      border-radius: 5px 5px 0px 0px;
  }
  .MuiOutlinedInput-input{
    padding: 11px 11px;
  }
  .MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
    padding-right: 11px;
    margin-top: 0px;
}
.MuiCheckbox-colorPrimary.Mui-checked {
    color: $theme-secondary;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  width: 110px;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border-color: $gray-c-color;border-width: 1px;}
.MuiSelect-select:focus{background: $white;}

.common_form .result .form_control .input_text {
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  color: $black;
  width: 110px;
  height: 40px;
  padding: 10px 10px 10px 10px;
}

.bottom_border {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 5px;
}
  
  .button_row {
      margin-top: 0;
      .btn_white {
          background: $white ;
          margin-left: 10px;
          border-radius: $theme-radius;
          padding: 0px 14px;
          font-size: 14px;
          font-weight: 400;
          height: 40px;
          text-decoration: none;
          text-transform: capitalize;
          color: $theme-secondary;
          &.blue_bg {
              background: $theme-secondary ;
              color: $white;
              margin-left: 10px;
              &:hover {
                  background: $theme-secondary ;
              }
          }
          &.disable {
              cursor: none;
              background: $disable;
              color: rgba(0, 0, 0, 0.26);
          }
      }
      .text_btn_theme {
          background: none;
      }
  }
  .outline_btn {
      border: 1px solid $theme-secondary;
      color: $theme-secondary;
      text-transform: capitalize;
      background: none;
  }
  .scroll_content {
      max-height: 100vh;
      overflow-y: auto;
  }
   
   .MuiLinearProgress-root {
          &.MuiLinearProgress-colorPrimary {
              background-color: $linear-loader-bg;
          }    
          .MuiLinearProgress-barColorPrimary {
              background-color: $theme-secondary;
          }
      }
      .MuiSelect-select{
        font-size: 12px;

      }

  .defult_form_configure {
      padding: 25px 50px;
      color: black;
      font-weight: 500;
      .form_col {
          &.single_line {
              margin-bottom: 10px;
          }
          .check_box_label {
              padding-top: 2px
          }
      }
      .form_col_check.checkbox {
          margin-bottom: 20px;
          .MuiCheckbox-root {
              padding-right: 4px;
              padding-left: 0;
              padding-top: 0;
              padding-bottom: 0;
              color: $theme-secondary;
          }
      }
      .form_col_configure {
          margin-bottom: -25px;
          margin-top: 10px;
      }
      
  }
}



.dialog_container.edit_detail_section.dialog_container_center.constraints_energy_levels .MuiDialog-paperWidthSm {
  max-height: 98vh; max-width: 470px; min-width: 470px;
}

.edit_detail_section.edit-user-info.constraints_energy_levels .single_line .MuiFormControl-root {
  width: calc(100% - 50px);
}

.dialog_container.edit_detail_section.constraints_energy_levels .btn_white.blue_bg {
  background: #25556E; color: #ffffff; margin-left: 10px;
}