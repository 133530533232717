@import "../../App.scss";

.user-name {
    padding: 29px 33px;
    border-radius: 5px;
    -webkit-border-radius: 5px;

    h2 {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: $theme;
    }
}

.welcome-message {
    font-family: "Open Sans", sans-serif;
    h2 {
        font-size: 16px;
        line-height: 5px;
        font-weight: 700;
        color: $black;
        padding: 12px 0 10px 13px;
        justify-content: left;
    }
    .h2-left {
        margin-left: 30px;
    }
    .about-host-portal{
        font-size: 14px;
        color: $black;
        justify-content: left;
        margin-left: 43px;
    }
    ul {
        list-style: none;
    }
    .span-text{
        padding-top: 10px;
        padding-top: 20px;
        font-size: 14px;
    }

    ul li::before {
        content: "\2022";
        color: $theme;
        font-weight:bolder;
        display: inline-block;
        line-height: 20px;
        width: 1em;
        margin-left: -1.2em;
        font-size: medium;
       
    }
    li{
        color: $black;
    }

    .border-left {
        border-left: 1px solid $placholder-color;
        margin-left: -30px
    }

    .welcome-btn {
        padding: 12px 0 22px 13px;
        margin-top: 5px;
        text-align: left;
        bottom: 20px;
        left: 0px;
        right: 0px;

        .btn {
            border-radius: 5px;
            border: 0;
            background: $theme;
            min-height: 30px;
            justify-content: left;
            font-size: 16px;
            font-weight: 400;
            color: $white;
            margin-bottom: 0px;
            text-transform: capitalize;

            &:hover {
                background: $theme-secondary;
            }
        }
    }
}