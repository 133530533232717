
@import "../../../App.scss";
  .history_charging_list .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child{
    stroke:$theme; stroke-opacity: 1
  }
  .history_charging_list .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke: $theme; stroke-opacity: 1
  }
  .recharts-yAxis .recharts-cartesian-axis-tick:first-child text {
     font-size: 0 !important;
   }

   .history_charging_list .graph_height .graph_height{outline: 5px solid $alert-marker;
    border-radius: 10px;}
    
  .no_line .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child{
    stroke:none !important;
  }
  .no_line .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke:$border-color !important;
  }

  /*--------Charge Fee Input Color--------*/
  .dialog_container.set_fee_dialog .list_form .result .form_control .input_text{ color: $gray-text-label; padding-right: 10px;}
  .dialog_container.set_fee_dialog .list_form .billing_detail_table .result .form_control .input_text{ color: $low-placecolor; }
  .dialog_container .list_form .result .form_control .input_text .MuiTypography-root{ color: $gray-text-label;}

  .dialog_container.set_fee_dialog .list_form .billing_detail_table .result .form_control .MuiInputAdornment-positionStart{ margin-right: 2px;}



  
  .dialog_container.set_fee_dialog .list_form .result.dis_text_value .form_control .input_text{color:$low-placecolor;}

  .dialog_container.set_fee_dialog .list_form .result.dis_ador_value .form_control .input_text{color:$low-placecolor; }

  .dialog_container.set_fee_dialog .list_form .result.dis_ador_value .form_control .input_text .MuiInputBase-input{text-align: left;}

  .dialog_container.set_fee_dialog .list_form .result.result.dis_ador_value .form_control .MuiInputAdornment-positionStart{ margin-right: 2px;}
  

  /*--------End Charge Fee Input Color--------*/

 #simple-popover .MuiPopover-paper{  background: none ; box-shadow: none ;} 

#transitions-popper{ z-index: 99999;
.popover{ background:none ;}
}



  .edit_event_dialog{
    .error_msg{ font-size: 12px; color: $alert-marker; margin: 20px 0px 0px 0px;}
    .MuiDialog-paper .dialog_title{ background:$body-bg; padding: 13px 30px ;
      h4{ margin-top: 10px ;}
    }
    .MuiDialog-paperWidthSm{ padding-bottom: 10px;   box-shadow: none; min-width: 481px !important; max-width: 481px !important;  background:$white; min-height: 178px;
     
      .edit_event_inside{  padding:20px 30px 30px 30px;
        h4{ margin: 0px; font-size: 16px; color:$black; margin-bottom: 20px; font-weight: 400;
        span{ font-weight: 500;}
        }
      .edit_evt_row{ width:46%; display: inline-block; padding-right: 25px; vertical-align: top;}
      .edit_evt_row + .edit_evt_row{ text-align: right; padding-right:0px;  width:calc(27% - 10px); display: inline-block; margin-left: 10px; vertical-align: top;}
       
        .select_control{ width:100%}
        .btn_white{ width: 100%; margin: 0px;}
        .label{ font-size: 14px; font-weight: 400; margin-bottom: 5px; color:$gray-text-label; text-align: left;}
        .result{ display: block;  width:100%; 
        &.label_select{display: block;}
        .label_legend{ margin-right: 10px; vertical-align: middle; margin-top: -2px; width: 15px; height: 15px; display: inline-block; border-radius: 3px; background: $gray-text1; border: 1px solid $dark-gray;
        &.peak{ background: $bg-peak; border-color: $peak ;}
        &.off-peak{ background: $off-peak-bg ; border-color: $off-peak ;}
        &.super-peak{ background: $super-peak-bg ; border-color:$super-peak ;}
        }
        }
      }
    }
  }
  

  
  .edit_detail_section .defult_form .form_col .single_line .form_result_sec{ width: calc(100% - 155px); display:inline-block; vertical-align: middle;}

.dialog_container.edit_detail_section .map_section{ height:190px; width: calc(100%); border-radius: 5px; display: inline-block; vertical-align: top;
    .sub_label {
      font-size: 10px;
      color: $gray-text-label;
      line-height: 16px;
      font-weight: 400;
      display: block;
  }
 .map_inside{ height: 155px; margin-bottom:0px;}
  }

 .zone_map_size{height: 135px;}
 
 .host_depot_info{height: 150px;}
