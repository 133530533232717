@import "../../../App.scss";

.monthly_reports  {
  .MuiInputBase-root {width: 200px}
  .station_details {
    .information_section .info_detail .tb_info_section .row .label.type_width {
      width: 48px;
      margin-top: 10px;
    }
    .information_section .info_detail .tb_info_section .row .label.month_width {
      width: 55px;
      margin-top: 10px;
    }
    .information_section .info_detail .tb_info_section .row .result .error_msg{
      width: 200px;
      left: 0;
    }
  }
    .report_list .default_table {
      max-width: 100%;
      .MuiDataGrid-row {background-color: $white;border-bottom: 1px solid $gray-text1; .MuiDataGrid-cell {border-bottom: 1px solid $gray-text1;} }
      .MuiDataGrid-row:last-child {border-bottom: none; .MuiDataGrid-cell {border-bottom: none;} }
      .MuiDataGrid-row:hover {background-color: $body-bg; .MuiDataGrid-cell {border-bottom: 1px solid $gray-text1;} }
      .MuiDataGrid-root .MuiDataGrid-row{background: $white;}
      .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {background: $white;}
      .MuiDataGrid-root .MuiDataGrid-row:hover {background: $body-bg;}
      .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {background: $body-bg;}
      .MuiCheckbox-root {color: $theme-secondary;}
    }
}

