@import "../../App.scss";

  .shift_group_row {
    .alert{display: inline-block; margin-right: 30px; margin-bottom: 10px;
    span{ margin-right: 5px  ;}
    }
    .port{display: inline-block; margin-bottom: 10px;
      span{ margin-right: 5px  ;}
    }
    .mb-0 {margin-bottom: 0;}
    .mt-12 {margin-top: 12px;}
  
    .depot_bar{
      span{ border-radius:5px;
      &:first-child{ margin-left: 0px;}
      }
    }
    
    .depot_state .state{margin: 14px 0px;
      span.active{ background: $theme;}
      span.suspended{ background: $driver-suspended;}
      span.pending{background:$driver-unavailable-bg}
      
    }
    span.active{ background: $theme;}
    span.suspended{ background: $driver-suspended;}
    span.pending{background:$driver-unavailable-bg}
  }

  .invitation_code {
    .label {
      color: $black;
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      vertical-align: top;
      width: 200px;
    }
    .result {
      word-wrap: break-word;
      color: $black;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      vertical-align: top;
      width: calc(100% - 200px);
    }
  }