@import "../../../../App.scss";

.waitlist_config{
  .text-primary {
    color: $theme-secondary;
  }

}
.edit_waitlist_config  {
  .MuiInputBase-root {
    width: 250px
  }
}

.dialog_container.edit_waitlist_config.sm_530 {
  .MuiDialog-paperWidthSm {
    min-width: 530px;
    max-width: 530px;
    max-height: 98vh;
  }
}

.manage_stations {
  .stations_assigned .default_table {
      max-width: 100%;
      .MuiDataGrid-row {background-color: $white;border-bottom: 1px solid $gray-text1; .MuiDataGrid-cell {border-bottom: 1px solid $gray-text1;} }
      .MuiDataGrid-row:last-child {border-bottom: none; .MuiDataGrid-cell {border-bottom: none;} }
      .MuiDataGrid-row:hover {background-color: $body-bg; .MuiDataGrid-cell {border-bottom: 1px solid $gray-text1;} }
      .MuiDataGrid-root .MuiDataGrid-row{background: $white;}
      .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {background: $white;}
      .MuiDataGrid-root .MuiDataGrid-row:hover {background: $body-bg;}
      .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {background: $body-bg;}
    .MuiCheckbox-root {color: $theme-secondary;}
  }
  .hide_select_all .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {display: none;}
}

