@import "../../App.scss";
.scroll_effect .host_settings .position_fixed_nav {
    top: 80px;
}
.host_settings {
    .pagination{position: static;}
    .position_fixed_nav {
        top: 80px;
    }
    .button_row {
        margin: 6px 0px 21px 0px;
    }
}

.default_table.show_pagination {
    .table {
      .MuiDataGrid-footerContainer { 
          display: initial; 
          .MuiDataGrid-selectedRowCount {
              display: none;
          }   
      }
    }
}

.delegates_details {
    .button_row {
        margin-top: 10px;
    }
    &.information_section .info_detail .tb_info_section .result .label {
        width: 100px;
    }
    &.information_section .info_detail.mg_user .tb_info_section .result {
    .label {width: inherit;}
    .result img { margin: 4px 0 0 10px;}
    }
    .avtar_user {
        //display: inline-block;
        text-align: center;
        .status_details {
            margin-top: 10px;
            span {
                background: $low-e-color;
                border-radius: 5px;
                border: 1px solid $offline-border;
                line-height: 28px;
                font-size: 10px;
                color: $white;
                min-width: 60px;
                height: 28px;
                display: inline-block;
                &.active {
                    background: $available-bg;
                    border: 1px solid $active-border;
                    margin-top: 21px;
                }
            }
        }
    }
}
.table_scroll .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
}
.host_settings .table_action_btn {
    background: $low-e-color;
    margin-left: 0px;
    border-radius: $theme-radius;
    padding: 0px 14px;
    font-size: 10px;
    font-weight: 400;
    height: 30px;
    text-decoration: none;
    text-transform: capitalize;
    color: $theme-secondary;
    &.active_btn {
        background: $available-bg;
        border: 1px solid $active-border;
        color: $white;
    }
    &.pending_btn {
        background: $offline-bg;
        border: 1px solid $offline-border;
        color: $white;
    }
}
.table_pic {
    vertical-align: middle;
    img {
        vertical-align: middle;
    }
}
.done_mark {
    vertical-align: middle;
    text-align: center;
    img {
        vertical-align: middle;
        margin-left: 5px;
    }
}

.table_locaion_details {
    .table_pic {
        display: inline-block;
        vertical-align: top;
    }
    .depot_info {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        .depot_name {
            font-size: 18px;
            font-weight: 400;
            color: $black;
            line-height: 22px;
            margin-bottom: 1px;
        }
        .depot_addr {
            opacity: 1;
            height: inherit;
            font-size: 14px;
            font-weight: 400;
            color: $text-color;
            line-height: 17px;
            margin-bottom: 1px;
        }
        .port_details {
            font-size: 12px;
            font-weight: 400;
            color: $text-color;
            line-height: 14px;
            span {
                margin-right: 40px;
            }
            span img {
                margin-right: 5px;
                display: inline-block;
                vertical-align: middle;
                margin-top: -2px;
            }
        }
    }
}

.edit_location_permission {
    &.dialog_containe .dialog_title h4 {
        margin-top: 10px;
    }
    .dialog_title {
        background: $body-bg;
        padding: 13px 30px;
        h4 {
            margin-top: 10px;
        }
    }
    .MuiDialogContent-dividers {
        padding: 40px 40px 30px 40px;
    }
}

.dialog_container {
    &.edit_photo_gallery {
        .MuiDialog-paperWidthSm {
            min-width: 725px;
            max-width: 725px;
            max-height: 98vh;
        }
        .dialog_title {
            background: $body-bg;
            padding: 13px 30px;
            h4 {
                margin-top: 10px;
            }

            
        }
        .items{ width: 100px;height: 100px;position: relative;display: inline-flex; margin: 24px 7px 12px 6px;
            img.main-image{border-radius: 6px; -webkit-border-radius:6px; width:100%; object-fit: cover;}
            .del{position: absolute;right: -6px;top: -10px;background: $gray-text1;padding: 5px;width: 23px;height: 23px;border-radius: 50%;-webkit-border-radius: 50%;border: 1px solid $white;}
          }
        .del-img,
        button span img {
            width: 18px;
        }
        .drag {
            position: absolute;
            left: 0;
            bottom: 5px;
            padding: 0;
        }

        .gallery_photo_upload {
            .MuiPaper-rounded {
                background-color: $body-bg;
                border: 1px dashed $available-border;
                box-shadow: none;
            }
            .MuiCardContent-root {
                max-width: 550px;
                margin: 0 auto;
            }
            .error {
                font-size: 12px;
                line-height: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                border: 1px solid $error-border;
                background-color: $error-bg;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                padding: 5px 0;
                text-align: center;
            }
            .dragdrop .MuiButton-containedPrimary cite {
                display: block;
                font-size: 12px;
                line-height: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                color: $text-color;
                font-style: normal;
                margin: 3px 0 0 0;
                &.error_text{color: $danger-red;}
            }
            .up-icon {
                width: 50px;
                display: block;
                margin: 18px auto 5px;
            }
            .dragdrop {
                position: relative;
                input[type="file"] {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
                .MuiButton-containedPrimary {
                    display: block;
                    background: none;
                    text-transform: inherit;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    color: $theme-secondary;
                    text-align: center;
                    box-shadow: none;
                }
            }
            .drag-text {
                font-size: 12px;
                line-height: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                color: $theme-secondary;
            }
            .drag-text.text-right {
                text-align: right;
            }
            .progressbar {
                margin: 10px 0 0 0;
                label {
                    float: left;
                    color: $text-color;
                    font-size: 12px;
                    line-height: 24px;
                    span {
                        color: $gray-text1;
                        margin: 0 0 0 2px;
                    }
                }
                .range {
                    width: 350px;
                    height: 8px;
                    background: $gray-text1;
                    border-radius: 10px;
                    float: left;
                    margin: 8px 0 0 5px;
                    .upper-range {
                        width: 230px;
                        height: 100%;
                        background: $complete-bg-dark;
                        border-radius: 10px;
                    }
                }
                .cancel-img,
                .error-img,
                .cancelred-img {
                    width: 18px;
                    margin: -8px 0 0 3px;
                    float: left;
                }
                .error-img,
                .cancelred-img {
                    display: none;
                }
            }
        }
        .progressbar.upload-error {
            .error-img,
            .cancelred-img {
                display: block;
            }
            .cancel-img {
                display: none;
            }
            .range .upper-range {
                background: $progress-stop;
                width: 100%;
            }
        }
    }
}

.dialog_container.account_delete {
    .MuiDialog-paperWidthSm {
        min-width: 446px;
        max-width: 446px;
        max-height: 98vh;
    }
    .dialog_title {
        background: $body-bg;
        padding: 13px 30px;
        h4 {
            margin-top: 10px;
        }
    }
    .action_msg {
        font-size: 14px;
        color: $black;
    }
}

.wrapper .information_section .info_detail .tb_info_section.mt-0 {
    margin-top: 0px;
}
.zones_sattings {
    .line-height {
        line-height: 18px;
    }
    .number_details {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        margin-left: 20px;
        .label {
            font-size: 10px;
            color: $gray-text-label;
        }
        .result {
            color: $black;
            font-size: 30px;
        }
    }
}
.selected_zones_list {
    .height_equal{  height: 100%;}
    margin-top: 20px;
    .zone_section {
        margin-bottom: 0px;
        padding: 0px;
    }
}
.dialog_container.zone_settings_dialog {

    .defult_form .form_col .error_msg {
        position: absolute;    
        color: $alert-marker;            
        font-size: 10px;            
        display: inline-block;            
        vertical-align: middle;            
        left:155px;
        top:42px;
    }

    .defult_form .form_col.error-show .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
        border-color: $alert-marker;
    }

    .MuiDialog-paperWidthSm {
        min-width: 570px;
        max-width: 570px;
        max-height: 98vh;
    }
    .delete_usergroup{background: $del-group; padding: 0 130px;line-height: 47px; text-transform: inherit; font-size: 14px; font-weight: 400; height: 47px; color:$white; border-radius: 5px; margin: 35px 0 20px 0;}
    .dialog_title {
        background: $body-bg;
        padding: 13px 30px;
        h4 {
            margin-top: 10px;
        }
    }
}
.capture_zone {
    .inpr_text {
        font-size: 14px;
        color: $black;
        margin-bottom: 16px;
    }
}
.zone_settings_dialog {
    .information_section {
        margin-top: 0px;
    }
}
.mt-40 {
    margin-top: 40px;
}
.dialog_container.dialog_container_center.delegate_account {
    .MuiDialog-paperWidthSm {
        min-width: 465px;
        max-width: 465px;
        max-height: 98vh;
    }
    .dialog_title {
        background: $body-bg;
        padding: 10px 30px;
        h4 {
            margin-top: 10px;
        }
        .MuiButton-root {
            min-width: 100px;
        }
        .blue_bg .MuiButton-label {
            display: block;
            height: 40px;
            line-height: 43px;
            font-size: 14px;
            color: $white;
        }
        .btn_white.text_btn_theme .MuiButton-label {
            display: block;
            height: 40px;
            line-height: 43px;
            color: $theme-secondary;
        }
    }
    .delegate_text {
        padding: 22px 0 32px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
    }
    .defult_form {
        .srv-validation-message{position:absolute;color: $alert-marker;font-size: 10px;line-height: 13px;font-weight: 400;}
        .form_col-12{position:relative;}
        .cite_error {
            position: absolute;
            left: 0;
            font-style: normal;
            bottom: -16px;
            font-size: 10px;
            line-height: 13px;
            color: $alert-marker;
            font-weight: 400;
            width:100%;
            .srv-validation-message{position:static;}
        }
        cite.cite-success {
            left: 0;
            font-style: normal;
            font-size: 10px;
            line-height: 13px;
            color: $parked-text;
            font-weight: 400;
        }
        .error-show {
            position: relative;
            .MuiOutlinedInput-notchedOutline {
                border-color: $alert-marker;
            }
            cite {
                position: absolute;
                left: 0;
                font-style: normal;
                bottom: -16px;
                font-size: 10px;
                line-height: 13px;
                color: $alert-marker;
                font-weight: 400;
                width:100%;
            }
            .MuiFormControl-root.MuiTextField-root .MuiInputBase-root {
                .MuiOutlinedInput-notchedOutline {
                    border-color: $alert-marker;
                }
            }
        }
        .MuiDialogContentText-root {
            margin-bottom: 18px;
        }
        .MuiSelect-select {
            background: url(../../assets/images/icons/ic_expand_more_24px.svg)
                no-repeat 97% center;
            height: 40px;
            padding: 0px 10px;
        }
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: $gray-text1;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $gray-text1;
            border-width: 1px;
        }
        .MuiSelect-selectMenu {
            font-size: 16px;
            color: $black;
            line-height: 40px;
            font-weight: 400;
        }
        .Mui-focused .MuiSelect-selectMenu{
            color:$black;
        }
        .MuiSelect-icon {
            display: none;
        }
        .MuiGrid-spacing-xs-4 > .MuiGrid-item {
            padding: 10px 19px;
            box-sizing: border-box;
        }
        h6 {
            margin: 0 0 10px 0;
            font-size: 10px;
            line-height: 15px;
            font-weight: 400;
            color: $placholder-color;
        }
        .MuiOutlinedInput-root {
            position: relative;
            width: 100%;
            border-radius: 6px;
            padding: 2px 0 0 0;
        }
        .MuiFormControl-root.MuiTextField-root {
            width: 100%;
            .MuiInputBase-root {
                font-size: 16px;
                color: $black;
                font-weight: 400;
                background: none;
                box-shadow: none;
                text-transform: capitalize;
                float: right;
                border-radius: 6px;
                padding: 0;
            }
            .Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: $theme;
            }
            .MuiSelect-outlined {
                border-radius: 6px;
            }
            .MuiOutlinedInput-input {
                padding: 12px 10px;
                border-radius: 6px;
            }
            .Mui-focused .MuiOutlinedInput-input {
                color: $black;
            }
        }
       
    }
}

.listMapThumb{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
}

.locationInfoMapThumb {
    width: 153px;
    height: 153px;
    border-radius: 5px;
    overflow: hidden;
}

.zone_location-table.default_table .MuiDataGrid-root .MuiDataGrid-row, .location_permission_table .MuiDataGrid-root .MuiDataGrid-row{cursor: pointer;}

.dialog_container .background-remove{
     .MuiDataGrid-row,.MuiDataGrid-row.Mui-selected{
      background: none; cursor: auto;
      .MuiDataGrid-cell{border-bottom: 1px solid rgba(224, 224, 224, 1)
    }
  }
  .MuiDataGrid-row:hover {border: none;}
   .MuiDataGrid-root .MuiDataGrid-cell:first-child,.MuiDataGrid-root .MuiDataGrid-cell:last-child {border: none;}
  }


  .accordian_table{ overflow: hidden; width: 100%;position: relative;
    .inner_progress.save_data {
        position: absolute;
        z-index: 1;
        right: 0;
        margin: 0 auto;
        left: 0;
        top: 20%;
    }
    .no_expandsummary {/* pointer-events: none;
       .MuiAccordionSummary-expandIcon .MuiSvgIcon-root{margin:41px 0 0 23px;background: url(/assets/images/icons/accordian_minus.svg) no-repeat;
            path{display: none;}
          } */
          &:before{display: none;}
    }
    .MuiTouchRipple-root{display: none;}
    .accordian_check{margin-top: 20px;
        .form_col.checkbox {
        .MuiCheckbox-root.MuiCheckbox-colorSecondary {color: rgba(0, 0, 0, 0.26);}
        .MuiCheckbox-root.Mui-checked {color: $theme-secondary;}
        .MuiCheckbox-colorSecondary:hover,.MuiCheckbox-colorPrimary:hover {background: none ;}
        .MuiCheckbox-root.Mui-checked.Mui-disabled {color: rgba(0, 0, 0, 0.26); }
        }
    }
    .inner_summary{
        .accordian_check{margin-top: 0; margin-bottom: 10px;}
        .defult_form .form_col.checkbox{
            margin-bottom: 0;
            vertical-align: top;
            margin-top: -13px;
        }
    }
    .depot_details{ 
        .depot_information { height: auto;
            .depot_location_map{ opacity: 1; width:70px; height: 70px;}
            .depot_info {
        .port_details {font-weight: 500;
          span{display: inline-block;}
        }
        .depot_addr{ height: auto; opacity: 1; margin-bottom: 5px;}
    }
}
}
    .MuiAccordionSummary-expandIcon .MuiSvgIcon-root{margin:32px 0 0 20px;background: url(/assets/images/icons/accordian_plus.svg) no-repeat;
      path{display: none;}
    }
    .MuiAccordionSummary-expandIcon.Mui-expanded{transform: rotate(0deg);
        .MuiSvgIcon-root{margin: 41px 0 0 20px;background: url(/assets/images/icons/accordian_minus.svg) no-repeat;}
    }
    .section_group{width: 100%; position: relative; margin: 0 0 20px 0 ; padding: 20px 0 0; }
    .MuiAccordionDetails-root .section_group {margin: 0; padding-bottom: 8px;}
    span{display: block; margin: 0 auto; text-align: center;
        img{margin-top: 30px;}
    }
    .inner_summary .section_group{margin: 0;
        &.location_setting{padding-bottom: 0;
            .defult_form.accordian_check{margin-bottom: 0;
                .form_col.checkbox {
                    margin-bottom: 0;
                    vertical-align: top;
                    margin-top: -8px;
                }}
            .lctnname{margin-top: -12px;}
            }
         }
    .text-left{text-align: left;}
    .MuiPaper-elevation1{box-shadow: none;}
    .MuiAccordion-root.border_none:before{border: none;}
    .MuiAccordion-root:before{background: none;  border-bottom: 1px solid $low-offlinecolor; margin: 0 35px;}
    .MuiIconButton-edgeEnd{ z-index: 1;margin: 0; position: absolute; left: 0; top:0;}
    .MuiAccordionSummary-root {padding: 0;
     &:before{background: none;}
    }
    .MuiAccordionDetails-root {padding: 8px 0 10px 6px;display: block;
        .section_title{ padding-top: 15px;}
        
    .no-row{font-size: 14px; margin: 10px 0;}
        span img {
            margin-top: 0px;
        }
        .section_group:last-child:before {
            display: none;
            }
        .section_group:before{content:""; position: absolute;border-bottom: 1px solid $low-offlinecolor; height: 1px; width: 88%;
            left: 103px; bottom:0;opacity: 0.4;}
    }
    .MuiAccordionDetails-root:before {content:""; position: absolute;border-bottom: 1px solid $low-offlinecolor; height: 1px; width: 96%;
        left: 32px;}
     .MuiAccordionDetails-root:after {content:""; position: absolute;border-bottom: 1px solid $low-offlinecolor; height: 1px; width: 96%;
            left: 32px; bottom:0}
    .MuiAccordionSummary-content.Mui-expanded, .MuiAccordionSummary-content, .MuiAccordion-root.Mui-expanded{margin: 0;  padding: 0; display: block; width: 100%;}
    .MuiAccordion-root.Mui-expanded:before {opacity: 1; }
    .section_title { text-align: center;
        &.mb{margin-bottom: 20px;}
        h2{ font-size: 12px;line-height: 16px;color:$theme-secondary; font-weight: 500; margin-bottom: -5px;}
        .text-none{display:none}
    }
    .border-bottom{ border-bottom: 1px solid $low-offlinecolor; height: 1px;}
    .space-right{margin: 0 0 20px 0; text-align: right;}
    .space-left{margin: 0 0 0 50px;}
    .lctnname{background: $progress_bar_bg; border-radius: 3px; color: $theme-secondary;float: left;padding: 7px 15px; margin-top: -10px; font-size: 12px;}
  }

  @media only screen and (max-width: 1570px) {

  .accordian_table .MuiAccordionDetails-root .section_group:before {left: 44px;}
  .accordian_table .MuiAccordionDetails-root:before, .accordian_table .MuiAccordionDetails-root:after {width: 92%; left: 32px;}
  }

  @media only screen and (max-width: 1130px) {
    .accordian_table {
    .MuiSvgIcon-root{margin:0;}
    .MuiAccordionDetails-root{padding: 0 0 0 6px;}
    .MuiAccordionSummary-expandIcon.Mui-expanded .MuiSvgIcon-root { margin: 40px 0 0 10px;}
    .MuiAccordionSummary-expandIcon .MuiSvgIcon-root{margin: 32px 0 0 10px;}
    .space-right {
        margin: 0 0 0 13px;
        text-align: center;
    }
}
  }
@media only screen and (min-width: 1024px) {
    .dialog_container.zone_settings_dialog .MuiDialog-paperWidthSm {
        max-height: 88vh;
    }    
  }

  .searchicon_inputfield{
    .MuiIconButton-root{padding: 0 10px 0 0;color:$charge-btn; transition: none;
        &:hover{background: none;}
    }
  }
  
  .host_membership_settings .table_action_btn {
    background: $low-e-color;
    margin-left: 0px;
    border-radius: $theme-radius;
    padding: 0px 14px;
    font-size: 10px;
    font-weight: 400;
    height: 30px;
    text-decoration: none;
    text-transform: capitalize;
    color: $theme-secondary;
    &.pending_btn {
        background: $gray-btn;
        border: 1px solid $gray-btn;
        color: $text-color;
    }
    &.rejected_btn {
        background: $membership-rejected;
        border: 1px solid $membership-rejected;
        color: $text-color;
    }
}