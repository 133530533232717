@import "../../App.scss";

div[role=tooltip] {
    .MuiPaper-root {
        .MuiTypography-root {
            .port_icon{ font-weight: 500; color: $theme-secondary; cursor: pointer;
                img{ margin-right: 14px; vertical-align: middle;}
            }
        }
    }
}