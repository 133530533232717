@import "../../../App.scss";

.station_route_map{ height: 50vh;
  margin-top: 13px; border-radius: 5px; overflow: hidden;
}

ul[role=menu] {
  top: auto !important;
  bottom: 100%;
}


.station_icon_maps {
  width: 67.999px; height: 69px;  background: url(../../../assets//images//icons/vehicle_current_location_blank.svg) no-repeat center center;
  text-align: center;
  span{ color: #25556E; font-size: 11px;
    padding-top:25%;
    font-weight: 500; text-align: center; display: block; line-height: 22px;
  }
}

.paginationCard {
  position:relative;
}

.display-flex {
  display: flex;
}

.lcd_display{
  background: url(../../../assets/images/image_gallery/lcd_blank.png) no-repeat; height: 160px;width: 310px;
}

.lcd_display .message_display{
  position: relative; vertical-align: middle; text-align: center;font-size: 13px; color: $lcd-display-text; width: 80%; top:40px;font-weight: 500;min-width: 310px
} 

.addNewMessage {
  color: $theme-secondary; font-weight: 600; cursor: pointer;
}

.message_content {
  font-size: 14px; font-weight: 500;
}

.dropdown_item_save {
  position: absolute; top: unset; z-index: 1; box-shadow: 0px 3px 10px $save-dropDown-bg;
}

.dialog_container .MuiDialog-paper .form_col .single_line .w_100per.full_w {
  width: 100%;
}

.add_display_message{
  .font_14 { font-size: 14px}
}

.lcd_msg_header {
  color: $theme-secondary; font-weight: 600; line-height: 24px; font-size: 15px;
}

.config_change_msg {
color: $gray-text-label; font-size: 14px; font-weight: 600;
}
