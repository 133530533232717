@import "../../App.scss";
.wrapper .shift_group_row {
   
  .depot_bar{
      span{ border-radius:5px ; margin-left: 0px;
      &:first-child{ margin-left: 0px;}
      }
    }
    
    .depot_state .state{margin: 14px 0px;
     
      span.available{ background: $available-bg;}
      span.connected{ background: $connected-bg;}
      span.reserved{ background: $reserved-bg;}
      span.charging{ background: $charging-bg ;}
      span.completed{ background: $complete-bg;}
      span.error{ background: $error-bg;}
      span.fault{ background: $fault-bg;}
      span.offline{ background: $offline-bg;}
  
    }
    
    span.charging{ background: $charging-bg;}
    span.available{ background:$available-bg;}
    span.offline{ background:$offline-bg;}
    span.reserved{ background:$reserved-bg;}
    span.error{ background:$error-bg;}
    span.fault{ background:$fault-bg;}
    span.connected{ background:$connected-bg;}
    span.completed{ background:$complete-bg;}


  } 

  .wrapper .evse_health{ 
    .state{ text-align: left ;
    .evse_health_bar{ background: $health-bar; height: 5px; border-radius: 6px; margin-bottom: 4px;}
    }
   
  }