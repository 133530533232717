.delegate {
  .MuiDialog-container .defult_form .form_col.checkbox .MuiCheckbox-colorPrimary.Mui-disabled{color: rgba(0, 0, 0, 0.26)}
  .table_locaion_details .table_pic{vertical-align: middle;}

  .MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
    svg{display: none;}
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox { display: none;}
  .MuiDataGrid-cellCheckbox{ display: none;}
  .dialog_container .background-remove .MuiDataGrid-root .MuiDataGrid-cellCheckbox.MuiDataGrid-cell:first-child{ display: none;}
  .dialog_container .MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox{ display: none;}

  .default_table {
    .show_pagination {
      .MuiDataGrid-footerContainer{
        display: initial;
        .MuiDataGrid-selectedRowCount {
          display: none;
        }
      }
    }
  }
}