$low-e-color: #eeeeee;
$white: #ffffff;

.polygonMap {
    height: 16em;
    width: 100%;
    position: relative;
    .polygonMapBtn {
        background-color: $white;
        border: 1px solid $low-e-color;
        position: absolute;
        z-index: 1;
        cursor: pointer;
        padding: 5px;
    }
    .clear {
        right: 86px;
        top: 5px;
    }
    .draw {
        right: 10em;
        top: 5px;
    }
    
}
